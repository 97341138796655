import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { updateChangepassword } from "../../actions/userActions";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer} from "react-toastify";
import keys from "../../actions/config";
import { withRouter } from "react-router-dom";

//import Actions

import {  changePassword } from '../../actions/admin';

//import lib
import { toastAlert } from '../../lib/toastAlert'

const url = keys.baseUrl;

const initailValue = {
    oldPassword: "",
     newPassword: "",
     confirmPassword:""
}
class Changepassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValue: initailValue,
            errors: {},
            
        };
    }

    
    onChange = e => {
        const { id, value } = e.target;
        const { formValue } = this.state

        const formData = { ...formValue, ...{ [id]: value } }
        this.setState({ formValue: formData, errors:{} });
    };
  

     onChangepasswordUpdate = async ( e )=> {
        e.preventDefault();
        const { oldPassword,newPassword,confirmPassword } = this.state.formValue;

        let reqData = {
            oldPassword,
            newPassword,
            confirmPassword
        }
        const { status, message ,errors} = await changePassword(reqData);
        if (status == "success") {
            this.setState({errors:{},formValue:initailValue})
            toastAlert('success', message, "changePassword");
        }else{
            toastAlert('error', message, "changePassword");
           this.setState({errors:errors})
        }
        
    };

    render() { 
        const { errors } = this.state;
        const { oldPassword,newPassword,confirmPassword } = this.state.formValue;

        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">


                            <h3 className="mt-2 text-secondary">Change Password</h3>
                            <form noValidate onSubmit={this.onChangepasswordUpdate} id="update-Changepassword">

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Old Password</label>
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                onChange={this.onChange}
                                                value={oldPassword}
                                                id="oldPassword"
                                                type="password"
                                                error={errors.oldPassword}
                                                className={classnames("form-control", {
                                                    invalid: errors.oldPassword
                                                })}/>
                                            <span className="text-danger">{errors.oldPassword}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password">New Password</label>
                                        </div>
                                        <div className="col-md-6">
                                           <input
                                                onChange={this.onChange}
                                                value={newPassword}
                                                id="newPassword"
                                                type="password"
                                                error={errors.newPassword}
                                                className={classnames("form-control", {
                                                    invalid: errors.newPassword
                                                })}/>
                                            <span className="text-danger">{errors.newPassword}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Confirm Password</label>
                                        </div>
                                        <div className="col-md-6">
                                        <input
                                                onChange={this.onChange}
                                                value={confirmPassword}
                                                id="confirmPassword"
                                                type="password"
                                                error={errors.confirmPassword}
                                                className={classnames("form-control", {
                                                    invalid: errors.confirmPassword
                                                })}/>
                                            <span className="text-danger">{errors.confirmPassword}</span>
                                        </div>
                                    </div>
                                </form>
                                    <br />
                                <button
                                    form="update-Changepassword"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update Password
                                </button>
                         </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Changepassword.propTypes = {
    updateChangepassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};



export default Changepassword;

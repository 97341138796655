import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "@ashvin27/react-datatable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

//import components
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import PriceCNVUpdateModal from "../partials/priceCNVupdateModal";

//import actions
import { getPassBookList } from "../../actions/admin";

// import lib
import { dateTimeFormat } from "../../lib/dateTimeHelper";
import isEmpty from "../../lib/isEmpty";

class PassBook extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "createdAt",
                text: "Date",
                className: "createdAt",
                width: 300,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return dateTimeFormat(record.createdAt, "YYYY-MM-DD HH:mm");
                },
            },
            {
                key: "tableId",
                text: "Id",
                className: "tableId",
                align: "left",
                sortable: true,
                width: 200,
                cell: record => {
                    return record.tableId ? record.tableId : '-'
                }
            },
            {
                key: "userCodeId",
                text: "User Id",
                className: "userCodeId",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "coin",
                text: "Coin",
                className: "coin",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "type",
                text: "Type",
                className: "type",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "category",
                text: "Category",
                className: "category",
                align: "left",
                sortable: true,
                // width: 200
            },
            {
                key: "beforeBalance",
                text: "Before Balance",
                className: "beforeBalance",
                align: "left",
                sortable: true,
                width: 200,
                cell: record => {
                    return isEmpty(record.beforeBalance) ? 0 : record.beforeBalance
                }
            },
            {
                key: "afterBalance",
                text: "After Balance",
                className: "afterBalance",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "amount",
                text: "Amount",
                className: "amount",
                align: "left",
                sortable: true,
                width: 200,
            },

        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "PassBook",
            no_data_text: "No Records found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            show_length_menu: false,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            editFormModal: false,
            editRecord: {},
            loader: false,
            page: 1,
            limit: 10,
            count: 0,
        };

        this.handlePagination = this.handlePagination.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit,
        }
        this.fetchData(reqData);
    }

    async fetchData(reqData) {
        try {
            reqData.userId = this.props.match.params['id']
            this.setState({ loader: true });
            const { status, loading, result, count } = await getPassBookList(reqData);
            this.setState({ loader: false });
            if (status) {
                this.setState({ "count": count, records: result });
            }
        } catch (err) { }
    }

    handlePagination(index) {
        let reqData = {
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value
        }
        this.fetchData(reqData);
        this.setState({ page: index.page_number, limit: index.page_size, search: index.filter_value })
    }
    render() {
        // const {  } = this.state;

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Passbook</h3>
                            <div
                                id="tbody_scroll"
                                style={{ overflowX: "auto" /*, maxHeight: 500*/ }}
                            >
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    loading={this.state.loader}
                                    dynamic={true}
                                    total_record={this.state.count}
                                    onChange={this.handlePagination}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PassBook;

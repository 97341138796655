import React, { Component } from "react";
import classnames from "classnames";
import { CKEditor } from "ckeditor4-react";
import Spinner from "react-bootstrap/Spinner";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import {
  getCopyTradeSettings,
  updateCopyTradeSettings,
} from "../../actions/copyTradeAction";

// import lib
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
  'maxfollower': '',
  'traderFee': '',
  'notes': ''
}

class CopyTradeSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      minimumDeposit: "",
      notes: "",
      errors: {},
      loading: false,
      loader: false,
      formvalue: initialFormValue,
    };
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (e) => {
    try {
      this.setState({ loading: true });
      let { status, loading, result } = await getCopyTradeSettings();
      if (status == 'success') {
        this.setState({
          formvalue: {
            maxfollower: result.maxfollower,
            traderFee: result.traderFee,
            notes: result.notes,
          },
        }, function () {
          this.setState({ loading });
        });
      } else {
        this.setState({ loading });
      }
    } catch (err) { }
  };

  updateSetting = async (e) => {
    e.preventDefault();
    try {
      let data = {
        traderFee: this.state.formvalue.traderFee,
        maxfollower: this.state.formvalue.maxfollower,
        notes: this.state.formvalue.notes,
      };
      this.setState({ loader: true });
      let { status, loading, message, error } =
        await updateCopyTradeSettings(data);
      this.setState({ loader: loading });
      if (status) {
        toastAlert("success", message, "addTemplate");
      } else {
        if (error) {
          this.setState({ errors: error });
        }
        toastAlert("error", message, "addTemplate");
      }
    } catch (err) { }
  };

  handleEditorChange(e) {
    let formData = { ...this.state.formvalue, 'notes': e.editor.getData() }
    this.setState({ formvalue: formData });
  }

  onChange = (e) => {
    let errors = { ...this.state.errors, ...{ [e.target.id]: "" } };
    this.setState({ errors: errors });
    this.setState({ [e.target.id]: e.target.value });
  };

  handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    let formData = { ...this.state.formvalue, [name]: value }
    this.setState({ formvalue: formData });
  };

  render() {
    const { minimumDeposit, errors, loader, loading } = this.state;
    const { maxfollower, traderFee, notes } = this.state.formvalue;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Become A Trader Settings</h3>
              {
                loading && <div className="row mt-1">
                  <div className="col-md-6 text-center">
                    <Spinner animation="border" size="sm" />
                  </div>
                </div>
              }
              {!loading && <>
                <form noValidate onSubmit={this.updateSetting} id="update-profile"    >
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="traderFee"> Trader Fee(%)</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="traderFee"
                        value={traderFee}
                        onChange={this.handleChange}
                        error={errors.traderFee}
                        className={classnames("form-control", {
                          invalid: errors.traderFee,
                        })}
                      />
                      <span className="text-danger">{errors.traderFee}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="maxfollower">Maximum Follower</label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="maxfollower"
                        value={maxfollower}
                        onChange={this.handleChange}
                        error={errors.maxfollower}
                        className={classnames("form-control", {
                          invalid: errors.maxfollower,
                        })}
                      />
                      <span className="text-danger">{errors.maxfollower}</span>
                    </div>
                  </div>


                  {/* <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="minimumDeposit">
                        Minimum Balance (INR)
                      </label>
                    </div>
                    <div className="col-md-6">
                      <input
                        onChange={this.onChange}
                        value={minimumDeposit}
                        id="minimumDeposit"
                        type="text"
                        error={errors.minimumDeposit}
                        className={classnames("form-control", {
                          invalid: errors.minimumDeposit,
                        })}
                      />
                      <span className="text-danger">
                        {errors.minimumDeposit}
                      </span>
                    </div>
                  </div> */}
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="notes">Notes</label>
                    </div>
                    <div className="col-md-6">
                      <CKEditor
                        config={{
                          extraAllowedContent: "div(*)",
                          allowedContent: true,
                          height: 200,
                        }}
                        initData={notes}
                        onChange={this.handleEditorChange}
                      />
                    </div>
                  </div>
                </form>
                <br />
                <button
                  form="update-profile"
                  type="submit"
                  className="btn btn-primary"
                  disabled={loader}
                >
                  Update
                </button>
              </>
              }
            </div>
          </div>
        </div >
      </div >
    );
  }
}

export default CopyTradeSettings;
import React from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import ReactDatatable from "@ashvin27/react-datatable";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { CSVLink } from "react-csv";
import { loginHisPagination } from "../../actions/admin";
import { momentFormat, dateTimeFormat } from '../../lib/dateTimeHelper'

class LoginHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
    };
    this.columns = [
      {
        key: "countryCode",
        text: "COUNTRY CODE",
        className: "countryCode",
        align: "left",
        sortable: true,
        width: "20px",
      },
      {
        key: "countryName",
        text: "COUNTRY NAME",
        className: "currency",
        align: "left",
        width: "20px",
        sortable: true,
      },
      {
        key: "regionName",
        text: "STATE",
        className: "currency",
        align: "left",
        width: "20px",
        sortable: true,
      },
      {
        key: "broswername",
        text: "BROWSER",
        className: "currency",
        align: "left",
        width: "20px",
        sortable: true,
      },
      {
        key: "ipaddress",
        text: "IP ADDRESS",
        className: "currency",
        align: "left",
        width: "20px",
        sortable: true,
      },
      {
        key: "os",
        text: "OS",
        className: "currency",
        align: "left",
        width: "20px",
        sortable: true,
      },
      {
        key: "ismobile",
        text: "MOBILE",
        className: "currency",
        align: "left",
        width: "10px",
        sortable: true,
      },
      {
        key: "createdDate",
        text: "CREATED DATE",
        className: "currency",
        align: "left",
        width: "10px",
        sortable: true,
        cell: record => {
          return dateTimeFormat(record.createdDate, 'YYYY-MM-DD HH:mm')
        }
      },
      {
        key: "status",
        text: "STATUS",
        className: "currency",
        align: "left",
        width: "10px",
        sortable: true,
      },
      {
        key: "reason",
        text: "REASON",
        className: "currency",
        align: "left",
        width: "10px",
        sortable: true,
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Currency",
      no_data_text: "No Currency found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      addFormModal: false,
      editFormModal: false,
      page: 1,
      limit: 10,
      count: 0,
      loader: false,
      records: [],
      imageUrl: "",
    };

    this.exportPDF = this.exportPDF.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.fetchloginPagList = this.fetchloginPagList.bind(this);
  }

  componentWillMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.fetchloginPagList(reqData);
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };

    this.fetchloginPagList(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  async fetchloginPagList(reqData) {
    this.setState({ loader: true });
    try {
      const { status, loading, message, result } = await loginHisPagination(
        reqData
      );
      this.setState({ loader: loading });
      if (status == "success") {
        this.setState({ count: result.count, records: result && result.data });
      }
    } catch (err) { }
  }

  async exportPDF() {
    const { records } = this.state;

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    const title = "LoginHistory";
    const headers = [
      [
        "countryCode",
        "countryName",
        "regionName",
        "broswername",
        "ipaddress",
        "os",
        "ismobile",
        "createdDate",
        "status",
        "reason",
      ],
    ];

    const data =
      records.length > 0 &&
      records.map((elt) => [
        // moment(elt.Date).format(
        //     "DD-MM-YYYY k:mm:s"
        // ),
        elt.countryCode,
        elt.countryName,
        elt.regionName,
        elt.broswername,
        elt.ismobile,
        elt.broswername,
        elt.ismobile,
        elt.createdDate,
        elt.status,
        elt.reason,

        // parseFloat(elt.TransferAmount).toFixed(2),
        // elt.Status
      ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("LoginHistory.pdf");
  }

  render() {
    const { records, count, loader } = this.state;
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />

          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div>
                <h3 className="mt-2 text-secondary">Login History</h3>
                {records && records.length > 0 ? (
                  <button
                    onClick={this.exportPDF}
                    className="btn btn-info"
                    style={{ width: "118px", height: "35px", fontSize: "13px" }}
                  >
                    Download(PDF)
                  </button>
                ) : (
                  ""
                )}

                {records && records.length > 0 ? (
                  <CSVLink
                    data={records}
                    filename={"LoginHistory.csv"}
                    className="btn btnTrade1 py-4"
                  >
                    <button
                      className="btn btn-info"
                      style={{
                        width: "118px",
                        height: "35px",
                        fontSize: "13px",
                      }}
                    >
                      Download(CSV)
                    </button>
                  </CSVLink>
                ) : (
                  ""
                )}

                {records && records.length > 0 ? (
                  <CSVLink data={records} filename={"LoginHistory.xls"}>
                    <button
                      className="btn btn-info"
                      style={{
                        width: "118px",
                        height: "35px",
                        fontSize: "13px",
                      }}
                    >
                      Download(XLS)
                    </button>
                  </CSVLink>
                ) : (
                  ""
                )}
              </div>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={count}
                loading={loader}
                onChange={this.handlePagination}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LoginHistory;

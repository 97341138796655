// import package
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import NewBot from "../partials/PerpTradeBot/NewBot";
import BotList from "../partials/PerpTradeBot/BotList";

// import action
import { activePerpBot } from "../../actions/tradingBot";
import { getPerpPairDropdown } from "../../actions/commonActions";

class Tradingbot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      currencyOptions: [],
      activeBotList: [],
    };

    this.getCurrencyData = this.getCurrencyData.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  // function
  componentDidMount() {
    this.getCurrencyData();
    this.fetchData();
  }

  async getCurrencyData() {
    const pairResp = await getPerpPairDropdown();
    if (pairResp.status == "success") {
      if (pairResp.result && pairResp.result.length > 0) {
        const currencyList = pairResp.result.reduce((prev, cur) => {
          if (
            prev.findIndex((el) => el.firstCurrencyId == cur.firstCurrencyId) ==
            -1
          ) {
            return [...prev, ...[cur]];
          }
          return prev;
        }, []);
        this.setState({ loader: false, currencyOptions: currencyList });
      }
    }
  }

  async fetchData() {
    try {
      this.setState({ loader: true });
      let { status, result } = await activePerpBot();
      if (status == "success") {
        this.setState({ activeBotList: result, loader: false });
      }
    } catch (err) { }
  }

  render() {
    const { currencyOptions, activeBotList, loader } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />

          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>

              <NewBot
                currencyOptions={currencyOptions}
                refetch={this.fetchData}
              />

              <br />

              <BotList
                activeBotList={activeBotList}
                loader={loader}
                refetch={this.fetchData}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tradingbot;

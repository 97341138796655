import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import authLogo from "../../images/authLogo2.png"

// import action
import { resetPassword } from "../../actions/authActions";

// import lib
import { toastAlert } from '../../lib/toastAlert';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            'password': '',
            'confirmPassword': '',

            errors: {}
        };
    }

  
componentDidMount(){
}
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = async e => {
        e.preventDefault();
        const { dispatch, history } = this.props;
        const {adminId}=this.props.match.params
        const reqData = {
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            adminId:adminId

        };
        try {
            const { status, loading, message, error } = await resetPassword(reqData);
            if (status == 'success') {
                toastAlert('success', message, 'resetPassword')
                history.push("/login");
            } else {
                if (error) {
                    this.setState({ errors: error })
                }
                toastAlert('error', message, 'login')
            }
        } catch (err) { }
    };

    render() {
        const { errors } = this.state;
        return (
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-4 mx-auto mt-5 card shadow-lg">
                        <div className="card-body p-2">
                            <center className="pt-3"><img src={authLogo} className="img-fluid" alt="" /></center>
                            <h2 className="text-center text-primary mt-3">Reset Password</h2>
                            <form noValidate onSubmit={this.onSubmit} className="white">
                               
                                <label htmlFor="password">New Password</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.password}
                                    error={errors.password}
                                    id="password"
                                    type="password"
                                    className={classnames("form-control", {
                                        invalid: errors.password
                                    })}
                                />
                                <span className="text-danger">{errors.password}</span>
                                <br />
                                <label htmlFor="password">Confirm Password</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.confirmPassword}
                                    error={errors.confirmPassword}
                                    id="confirmPassword"
                                    type="password"
                                    className={classnames("form-control", {
                                        invalid: errors.password
                                    })}
                                />
                                <span className="text-danger">{errors.confirmPassword}</span>
                                <p className="text-center pb-0 mt-2">
                                    <button
                                        type="submit"
                                        className="btn btn-large btn-primary mt-2 px-5">
                                        Submit
                                    </button>
                                </p>
                                <p className="grey-text text-darken-1">
                                    Go to login? <Link to="/login">Login</Link>
                                </p>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, null)(Login);

// import config
import axios from '../config/axios';

export const spotOrderHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/spotOrderHistory`,
            'params': data
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const spotTradeHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/spotTradeHistory`,
            'params': data
        });
        console.log(respData.data.result, '/////////////////////')

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const perpetualOrderHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/perpetualOrderHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const perpetualMaintencance = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/perpetualMaintencance`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const perpetualTradeHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/perpetualTradeHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const perpetualFundingHistory = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/perpetualFundingHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const futureOrderHistory = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/future/orderHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const futureTradeHistory = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/future/tradeHistory`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
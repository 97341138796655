import axios from 'axios'



export const DashboardHist = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/get-history`,

        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const getChartData=async(params)=>{

    try {
        let respData = await axios({
          method: "get",
          url: `/adminapi/getChartData`,
          params:params
        });
        return {
          status: "success",
          loading: false,
          result: respData.data.result,
        };
      } catch (err) {
        return {
          status: "failed",
          loading: false,
          error: err.response.data.errors,
        };
      }
  }


  export const getDashboardDet = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/getDashboardDetails`,

        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}
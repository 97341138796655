import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";

// import action
import { smsSetting } from '../../actions/siteSettingActions';

//import lib
import { toastAlert } from '../../lib/toastAlert';
import isEmpty from '../../lib/isEmpty'

const initialFormValue = {
    "limit": "",
    "interval": "",
}

class SMS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValue: initialFormValue,
            loader: false,
            errors: {}
        };
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { records } = nextProps;
        if (records && records.sms) {
            this.setState({
                formValue: {
                    "limit": records.sms.limit,
                    "interval": records.sms.interval
                }
            })
        }
    }

    handleChange(e) {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...this.state.formValue, [name]: value };
        this.setState({ formValue: formData });
        if (!isEmpty(value)) {
            this.setState({ errors: {} })
        }
    }

    async handleSubmit(e) {
        e.preventDefault()
        const { formValue } = this.state;
        this.setState({ 'loader': true })
        try {
            const { status, message, errors } = await smsSetting(formValue);
            this.setState({ 'loader': false })
            if (status == 'success') {
                toastAlert('success', message, 'sitesetting')
            } else {
                if (errors) {
                    this.setState({ errors: errors })
                    return
                }
                toastAlert('error', message, 'sitesetting')
            }
        } catch (err) {
        }
    }

    render() {
        const { errors, loader } = this.state;
        const {
            limit,
            interval
        } = this.state.formValue;

        return (
            <Card>
                <Card.Header>SMS</Card.Header>
                <Card.Body>
                    <div className='row mt-2'>
                        <div className='col md-12'>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="currencyName">Limit</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        value={limit}
                                        onChange={this.handleChange}
                                        name="limit"
                                        class='form-control'
                                        error={errors && errors.limit}
                                        id="limit"
                                        type="number"
                                        min="1"
                                    />
                                    <span className="text-danger">{errors && errors.limit}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="currencyName">Interval (min)</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        value={interval}
                                        onChange={this.handleChange}
                                        name="interval"
                                        class='form-control'
                                        error={errors && errors.interval}
                                        id="interval"
                                        type="number"
                                        min="1"
                                    />
                                    <span className="text-danger">{errors && errors.interval}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Button
                        disabled={loader}
                        onClick={this.handleSubmit}
                    >
                        {loader && <i class="fas fa-spinner fa-spin"></i>}
                        Submit
                    </Button>
                </Card.Footer>
            </Card>
        )
    }
}

export default SMS;
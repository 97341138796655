import React, { Component } from "react";
import ReactDatatable from '@ashvin27/react-datatable';

//import components
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

//import actions
import { smsLog } from '../../actions/admin';

// import lib
import { momentFormat } from '../../lib/dateTimeHelper'

class SmsLog extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "userCode",
                text: "User Id",
                className: "tableId",
                align: "left",
                sortable: true,
                width: 200
            },
            {
                key: "phoneCode",
                text: "Phone Code",
                className: "phoneCode",
                align: "left",
                sortable: true,
                width: 200
            },
            {
                key: "phoneNo",
                text: "Phone No",
                className: "phoneNo",
                align: "left",
                sortable: true,
                width: 200
            },
            {
                key: "createdAt",
                text: "Date",
                className: "createdAt",
                width: 300,
                align: "left",
                sortable: false,
                cell: record => {
                    return momentFormat(record.createdAt, 'YYYY-MM-DD HH:mm')
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Sms Log",
            no_data_text: 'No Records found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: false,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            editFormModal: false,
            editRecord: {},
            loader: false,
            page: 1,
            limit: 10,
            count: 0,
        };
        this.fetchData = this.fetchData.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
    }

    componentDidMount() {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit
        }
        this.fetchData(reqData);
    };

    async fetchData(reqData) {
        try {
            this.setState({ 'loader': true })

            const { status, loading, result, count } = await smsLog(reqData);
            this.setState({ 'loader': loading })
            if (status) {
                this.setState({ "count": count, 'records': result })
            }
        } catch (err) { }
    }

    handlePagination(index) {
        let reqData = {
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value
        }
        this.fetchData(reqData);
        this.setState({ page: index.page_number, limit: index.page_size, search: index.filter_value })
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Sms Log</h3>
                            <div id="tbody_scroll" style={{ overflowX: 'auto'/*, maxHeight: 500*/ }}>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.count}
                                    onChange={this.handlePagination}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

}

export default SmsLog;
import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import { momentFormat } from "../../lib/dateTimeHelper";
import { toFixedDown } from "../../lib/roundOf";
//import components

import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import isEmpty from '../../lib/isEmpty'
//import actions
import { spotTradeHistory } from "../../actions/reportActions";
//import downloads
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";

class tradehistory extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "createdAt",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: true,
        width: 300,
        cell: (record) => {
          return <p>{momentFormat(record.createdAt, "YYYY-MM-DD HH:mm")}</p>;
        },
      },
      {
        key: "firstCurrency",
        text: "Base Currency",
        className: "pairName",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "buyUserId",
        text: "Buyer Id",
        className: "Buyer",
        align: "left",
        sortable: true,
        width: 200,
        cell: record => {
          return record && record.liquidityType === "bybit" ? 'Bybit match' : record.buyUserId
        }
      },
      {
        key: "sellUserId",
        text: "Seller Id",
        className: "Seller",
        align: "left",
        sortable: true,
        width: 200,
        cell: record => {
          return record && record.liquidityType === "bybit" ? 'Bybit match' : record.sellUserId
        }
      },
      {
        key: "secondCurrency",
        text: "Quote Currency",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "buyorsell",
        text: "Side",
        className: "Side",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "liquidityType",
        text: "Liquidity Type",
        className: "orderType",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "orderType",
        text: "order Type",
        className: "orderType",
        align: "left",
        sortable: true,
        width: 200,
      },

      {
        key: "price",
        text: "Price",
        className: "Price",
        align: "left",
        sortable: true,
        width: 200,
        cell: record => {
          return <p>{record && toFixedDown(record.price, 8)}</p>
        }
      },

      {
        key: "filledQuantity",
        text: "Executed",
        className: "Executed",
        align: "left",
        sortable: true,
        width: 200,
        cell: record => {
          return <p>{record && toFixedDown(record.filledQuantity, 8)}</p>

        }
      },
      {
        key: "orderValue",
        text: "Total",
        className: "Total",
        align: "left",
        sortable: true,
        width: 200,
        cell: record => {
          return <p>{record && toFixedDown(record.orderValue, 8)}</p>

        }
      },
      {
        key: "Fees",
        text: "Fees",
        className: "Fees",
        align: "left",
        sortable: true,
        width: 200,
        cell: record => {
          return <p>{record && toFixedDown(record.Fees, 8)}</p>

        }
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Order",
      no_data_text: "No Records found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };
    this.state = {
      records: [],
      loader: false,
      page: 1,
      limit: 10,
      count: 0,
    };
    this.handlePagination = this.handlePagination.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
  }
  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getData(reqData);
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getData(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  async getData(reqData) {
    try {
      this.setState({ loader: true });

      const { status, loading, result } = await spotTradeHistory(reqData);
      this.setState({ loader: false });
      if (status == "success") {
        this.setState({ count: result.count, records: result.data });
      }
    } catch (err) { }
  }

  async exportPDF() {
    const { records } = this.state;

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    const title = "SpotTradeHistory";
    const headers = [
      [
        "Date",
        "Base Currency",
        "Buyer Id",
        "seller Id",
        "Quote Currency",
        "Side",
        "order Type",
        "Price",
        "Excuted",
        "Total",
        "Fees",
      ],
    ];

    const data =
      records.length > 0 &&
      records.map((elt) => [
        elt.createdAt,
        elt.firstCurrency,
        elt.buyUserId,
        elt.sellUserId,
        elt.secondCurrency,
        elt.buyorsell,
        elt.orderType,
        elt.price,
        elt.filledQuantity,
        elt.orderValue,
        elt.Fees,
      ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("SpotTradeHistory.pdf");
  }

  render() {
    const { records, count } = this.state;
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Spot Trade History</h3>
              {records && records.length > 0 ? (
                <button
                  onClick={this.exportPDF}
                  className="btn btn-info"
                  style={{ width: "118px", height: "35px", fontSize: "13px" }}
                >
                  Download(PDF)
                </button>
              ) : (
                ""
              )}

              {records && records.length > 0 ? (
                <CSVLink
                  data={records}
                  filename={"SpotTradeHistory.csv"}
                  className="btn btnTrade1 py-4"
                >
                  <button
                    className="btn btn-info"
                    style={{ width: "118px", height: "35px", fontSize: "13px" }}
                  >
                    Download(CSV)
                  </button>
                </CSVLink>
              ) : (
                ""
              )}

              {records && records.length > 0 ? (
                <CSVLink data={records} filename={"SpotTradeHistory.xls"}>
                  <button
                    className="btn btn-info"
                    style={{ width: "118px", height: "35px", fontSize: "13px" }}
                  >
                    Download(XLS)
                  </button>
                </CSVLink>
              ) : (
                ""
              )}
              <div style={{ overflow: "scroll" }}>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={count}
                  loading={this.state.loader}
                  onChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default tradehistory;

import React, { Component, Fragment } from "react";

//import compoents
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import ReactDatatable from '@ashvin27/react-datatable';

//import Actions
import { perpetualOrderHistory } from '../../actions/reportActions'

//Downloads
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { CSVLink } from "react-csv";

//import lib
import { momentFormat } from '../../lib/dateTimeHelper'

class fundinghistory extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "orderDate",
                text: "Contracts",
                className: "Date",
                align: "left",
                sortable: true,
                width: 200,
                cell: record => {
                    return momentFormat(record.orderDate, "DD-MM-YYYY HH:mm")
                }

            },
            {
                key: "userCode",
                text: "User Code",
                className: "Date",
                align: "left",
                sortable: true,
                width: 200,
            },
            {
                key: "Contract",
                text: "Contract",
                className: "Amount",
                align: "left",
                sortable: true,
                width: 200,
                cell: record => {
                    return <p>{record.firstCurrency}/{record.secondCurrency}</p>
                }
            },
            {
                key: "Filled/Total",
                text: "Filled/Total",
                className: "pairName",
                align: "left",
                sortable: true,
                width: 200,
                cell: record => {
                    return <p>{record.filledQuantity}/{record.quantity}</p>
                }
            },
            {
                key: "price",
                text: "Order price",
                className: "name",
                align: "left",
                sortable: true,
            },

            {
                key: "buyorsell",
                text: "Trade Type",
                className: "Side",
                align: "left",
                sortable: true,
                width: 200,
            },

            {
                key: "orderType",
                text: "Order Type",
                className: "Price",
                align: "left",
                sortable: true,
                width: 200,
            },

            {
                key: "_id",
                text: "order NO",
                className: "Total",
                align: "left",
                sortable: true,
                width: 200,
            },

        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Order",
            no_data_text: 'No Email Templates found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: false,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.state = {
            records: [],
            loader: false,
            page: 1,
            limit: 10,
            count: 0,
            document: []


        };
        this.handlePagination = this.handlePagination.bind(this);
        this.exportPDF = this.exportPDF.bind(this);

    }
    componentDidMount() {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit
        }
        this.getPerpetualOrder(reqData)
    };

    handlePagination(index) {
        let reqData = {
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value
        }
        this.getPerpetualOrder(reqData);
        this.setState({ page: index.page_number, limit: index.page_size, search: index.filter_value })
    }


    async getPerpetualOrder(reqData) {
        try {
            let arr = []
            this.setState({ 'loader': true })
            const { status, loading, result } = await perpetualOrderHistory(reqData);
            this.setState({ 'loader': loading })
            if (status == 'success') {
                this.setState({ "count": result.count, 'records': result.data })
                result && result.data && result.data.length > 0 && result.data.map(item => {
                    return (
                        arr.push({
                            'Date': item.orderDate,
                            'user Code': item.userCode,
                            'Contract': `${item.firstCurrency} / ${item.secondCurrency}`,
                            'Filled/Total': `${item.filledQuantity} / ${item.quantity}`,
                            'Order price': item.price,
                            'Trade Type': item.buyorsell,
                            'Order Type': item.orderType,
                            'Order No': item._id
                        })
                    )
                })
                this.setState({ 'document': arr })
            }
        } catch (err) { }
    }


    async exportPDF() {
        const { records } = this.state

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);

        const title = "perpetualOrderHistory";
        const headers = [
            [
                'Date',
                'user Code',
                'Contract',
                'Filled/Total',
                'Order price',
                'Trade Type',
                'Order Type',
                'Order No'
            ],
        ];

        const data =
            records.length > 0 &&
            records.map((elt) => [
                elt.orderDate,
                elt.userCode,
                `${elt.firstCurrency} / ${elt.secondCurrency}`,
                `${elt.filledQuantity} / ${elt.quantity}`,
                elt.price,
                elt.buyorsell,
                elt.orderType,
                elt._id
            ]);

        let content = {
            startY: 50,
            head: headers,
            body: data,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("perpetualOrderHistory.pdf");
    }


    render() {

        const { records, count, loader, document } = this.state

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Perpetual Funding Rate</h3>

                            {records && records.length > 0 ? (
                                <button onClick={this.exportPDF} className='btn btn-info' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(PDF)</button>
                            ) : (
                                ""
                            )}

                            {records && records.length > 0 ? (
                                <CSVLink
                                    data={document}
                                    filename={"perpetualOrderHistory.csv"}
                                    className="btn btnTrade1 py-4"
                                >
                                    <button className='btn btn-info' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(CSV)</button>
                                </CSVLink>
                            ) : (
                                ""
                            )}

                            {records && records.length > 0 ? (
                                <CSVLink
                                    data={document}
                                    filename={"perpetualOrderHistory.xls"}

                                >
                                    <button className='btn btn-info' style={{ width: '118px', height: '35px', fontSize: '13px' }}>Download(XLS)</button>
                                </CSVLink>
                            ) : (
                                ""
                            )}
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                total_record={count}
                                dynamic={true}
                                loading={loader}
                                onChange={this.handlePagination}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}



export default (fundinghistory);

import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";

//import components
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

//import actions
import { spotOrderHistory } from "../../actions/reportActions";

// import lib
import { momentFormat } from "../../lib/dateTimeHelper";
import { toFixed } from '../../lib/roundOf'

//import downloads
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";

class tradehistory extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "orderDate",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: true,
        width: 500,
        cell: (record) => {
          return momentFormat(record.orderDate, "YYYY-MM-DD HH:mm");
        },
      },
      {
        key: "email",
        text: "Email",
        className: "Total",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "firstCurrency",
        text: "Base Currency",
        className: "pairName",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "secondCurrency",
        text: "Quote Currency",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "orderType",
        text: "Type",
        className: "Type",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "buyorsell",
        text: "Side",
        className: "Side",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "averageTotal",
        text: "Excuted price",
        className: "Average",
        align: "left",
        sortable: true,
        width: 200,
        cell: record => {
          return record.averageTotal <= 0 ? 0 : record.averageTotal
        }
      },
      {
        key: "price",
        text: "Price",
        className: "Price",
        align: "left",
        sortable: true,
        width: 200,
      },

      {
        key: "filledQuantity",
        text: "Executed",
        className: "Executed",
        align: "left",
        sortable: true,
        width: 200,
        cell: record => {
          return record.filledQuantity <= 0 ? 0 : record.filledQuantity
        }
      },
      {
        key: "quantity",
        text: "Amount",
        className: "Amount",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "total",
        text: "Total",
        className: "Amount",
        align: "left",
        sortable: true,
        width: 200,
        cell: record => {
          let totalVal = record.averageTotal > 0
            ? toFixed(record.averageTotal / record.filledQuantity, 4)
            : 0;
          return toFixed(totalVal * record.filledQuantity, 8)
        }
      },
      {
        key: "liquidityType",
        text: "Liquidity Type",
        className: "Total",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "status",
        text: "order status",
        className: "Total",
        align: "left",
        sortable: true,
        width: 200,
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Order",
      no_data_text: "No Records found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };
    this.state = {
      records: [],
      loader: false,
      page: 1,
      limit: 10,
      count: 0,
      report: []
    };
    this.handlePagination = this.handlePagination.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
  }
  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getData(reqData);
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getData(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  async getData(reqData) {
    try {
      this.setState({ loader: true });
      const { status, loading, result } = await spotOrderHistory(reqData);
      console.log('result: ', result);
      this.setState({ loader: false });
      if (status == "success") {
        let array = []
        result.data && result.data.length > 0 && result.data.map(item => {
          array.push({
            "Date": item.orderDate,
            "Email": item.email,
            "Base Currency": item.firstCurrency,
            "Quote Currency": item.secondCurrency,
            "Type": item.orderType,
            "Side": item.buyorsell,
            "Excuted price": item.avarageTotal ? item.avarageTotal : 0,
            "Price": item.price ? item.price : 0,
            "Executed": item.filledQuantity ? item.filledQuantity : 0,
            "Amount": item.quantity ? item.quantity : 0,
            "Total": item.averageTotal ? item.averageTotal / item.filledQuantity * item.filledQuantity : 0,
            "order Status": item.status,
            "Liquidity Type": item.liquidityType
          })

        })
        this.setState({ report: array });
        this.setState({ count: result.count, records: result.data, });
      }
    } catch (err) { }
  }

  async exportPDF() {
    const { records } = this.state;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    const title = "SpotOrderHistory";
    const headers = [
      [
        "Date",
        "email",
        "Base Currency",
        "Quote Currency",
        "Type",
        "Side",
        "Avarage",
        "Price",
        "Executed",
        "Amount",
        "Total",
        "order Status",
        "Liquidity Type"
      ],
    ];

    const data =
      records.length > 0 &&
      records.map((elt) => [
        elt.orderDate,
        elt.email,
        elt.firstCurrency,
        elt.secondCurrency,
        elt.orderType,
        elt.buyorsell,
        elt.averagePrice,
        elt.price,
        elt.filledQuantity,
        elt.quantity,
        toFixed(elt.averageTotal / elt.filledQuantity, 4) * elt.filledQuantity,
        elt.status,
        elt.liquidityType
      ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("SpotOrderHistory.pdf");
  }

  render() {
    const { records, count, report } = this.state;
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Spot Order History</h3>

              {records && records.length > 0 ? (
                <button
                  onClick={this.exportPDF}
                  className="btn btn-info"
                  style={{ width: "118px", height: "35px", fontSize: "13px" }}
                >
                  Download(PDF)
                </button>
              ) : (
                ""
              )}

              {records && records.length > 0 ? (
                <CSVLink
                  data={report}
                  filename={"SpotOrderHistory.csv"}
                  className="btn btnTrade1 py-4"
                >
                  <button
                    className="btn btn-info"
                    style={{ width: "118px", height: "35px", fontSize: "13px" }}
                  >
                    Download(CSV)
                  </button>
                </CSVLink>
              ) : (
                ""
              )}

              {records && records.length > 0 ? (
                <CSVLink data={report} filename={"SpotOrderHistory.xls"}>
                  <button
                    className="btn btn-info"
                    style={{ width: "118px", height: "35px", fontSize: "13px" }}
                  >
                    Download(XLS)
                  </button>
                </CSVLink>
              ) : (
                ""
              )}
              <div style={{ overflow: "scroll" }}>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={count}
                  loading={this.state.loader}
                  onChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default tradehistory;

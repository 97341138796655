// import lib
import axios from "../config/axios";

export const newTradeBot = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/newTradeBot`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const activeBot = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/activeBot`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const newBotUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/botUser`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const deleteBot = async (botId) => {
  try {
    let respData = await axios({
      method: "delete",
      url: `/adminapi/deleteBot/${botId}`,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};



export const newPerpTradeBot = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/newPerpTradeBot`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const activePerpBot = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/activePerpBot`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const deletePerpBot = async (botId) => {
  try {
    let respData = await axios({
      method: "delete",
      url: `/adminapi/deletePerpBot/${botId}`,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    FORGOT,
    USER_LOADING
} from "./types";
import keys from "./config";

import config from '../config'

// import config
import axios from '../config/axios';
import { toastAlert } from '../lib/toastAlert'

const url = keys.baseUrl;
export const registerUser = (userData, history) => dispatch => {
    axios
        .post(url + "api/user-add", userData)
        .then(res => history.push("/login"))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const loginUser = userData => dispatch => {
    axios.post("api/login", userData)
        .then(res => {
            const { token } = res.data;
            // localStorage.setItem("jwtToken", token);
            localStorage.setItem("adminJwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.code
            })
        );
};
export const forget = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/adminapi/forget`,
            data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}
export const resetPassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/adminapi/resetPassword`,
            data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}


export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};

export const logoutUser = () => dispatch => {
    let token = localStorage.getItem("admin_token")
    logout(token)
    localStorage.removeItem("admin_token");
    toastAlert('success', "Logout succeessfully", 'logout')
    setAuthToken(false);
    dispatch(setCurrentUser({}));
};

export const logout = async (token) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': '/adminapi/logout',
            headers: {
                token
            }
        });
        if (respData.data.success == true) {
            localStorage.removeItem("admin_token");
            window.location.reload();
        }
        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "@ashvin27/react-datatable";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import FaqAddModal from "../partials/FaqAddModal";
import FaqUpdateModal from "../partials/FaqUpdateModal";

// import action
import { getFaqCategory, faqList } from "../../actions/faqActions";
import { getNotification } from "../../actions/admin";

import { momentFormat } from "../../lib/dateTimeHelper";

class Notification extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "created_date",
        text: "Date",
        className: "question",
        align: "left",
        sortable: true,
        width: 200,
        cell: (record) => {
          return <p>{momentFormat(record.created_date)}</p>;
        },
      },
      {
        key: "type",
        text: "Notification Type",
        className: "question",
        align: "left",
        sortable: true,
        width: 200,
      },
      {
        key: "description",
        text: "Description",
        className: "description",
        align: "left",
        sortable: true,
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Faq",
      no_data_text: "No Faq found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      addFormModal: false,
      editFormModal: false,
      editRecord: {},
      records: [],
      search: "",
      page: 1,
      limit: 10,
      count: 0,
      param: "showAll",
      loader: false,
      categoryOption: [],
    };

    this.fetchNotification = this.fetchNotification.bind(this);
    this.refetch = this.refetch.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { page, limit, param } = this.state;
    let reqData = {
      page,
      limit,
      param,
    };
    this.fetchNotification(reqData);
  }

  async fetchNotification(reqData) {
    try {
      this.setState({ loader: true });
      const { status, loading, result, count } = await getNotification(reqData);
      if (status == "success") {
        this.setState({ loader: false });
        this.setState({ count: count, records: result });
      }
    } catch (err) {}
  }

  refetch() {
    const { page, limit, param } = this.state;
    let reqData = {
      page,
      limit,
      param,
    };
    this.fetchNotification(reqData);
  }

  handlePagination(index) {
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
      param: this.state.param,
    };
    this.fetchNotification(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  render() {
    const { loader, count } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />

          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Notification History</h3>

              <div style={{ overflow: "scroll" }}>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={count}
                  loading={loader}
                  onChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Notification;

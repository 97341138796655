import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';

//import components

import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

//import Actions
import { walletMonitor } from '../../actions/walletAction'


class Userbalance extends Component {
    constructor(props) {
        super(props);
        this.columns = [

            {
                key: "coin",
                text: "Coin",
                className: "userId",
                align: "left",
                sortable: true,
            },
            {
                key: "spotBal",
                text: "Spot Balance",
                className: "currency",
                align: "left",
                sortable: true,
                cell: record => {
                    return record.spotBal > 0 ? record.spotBal : 0
                }
            },

            {
                key: "derivativeBal",
                text: "Perpetual Balance",
                className: "balance",
                align: "left",
                sortable: true,
                cell: record => {
                    return record.derivativeBal > 0 ? record.derivativeBal : 0
                }

            },
            {
                key: "derivativeWallet",
                text: "Total Balance(Exchange)",
                className: "derivativeWallet",
                align: "left",
                sortable: true,
                cell: record => {
                    let total = record.spotBal + record.derivativeBal
                    return total > 0 ? total : 0
                }
            },
            {
                key: "free",
                text: "Bybit Free",
                className: "derivativeWallet",
                align: "left",
                sortable: true,
                cell: record => {
                    return record.free && record.free > 0 ? record.free : 0
                }
            },
            {
                key: "locked",
                text: "Bybit Locked",
                className: "derivativeWallet",
                align: "left",
                sortable: true,
                cell: record => {
                    return record.locked && record.locked > 0 ? record.locked : 0
                }
            },


        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Userbalance",
            no_data_text: 'No Userbalance found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            loader: false,
            page: 1,
            limit: 10,
            count: 0,
            responsive: true,
            loader: false,

        };
        this.walletBal = this.walletBal.bind(this);
    }
    componentDidMount() {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit
        }

        this.walletBal(reqData)
    };

    async walletBal(reqData) {
        try {
            this.setState({ 'loader': true })
            const { status, loading, result } = await walletMonitor(reqData);
            this.setState({ 'loader': false })
            if (status == 'success') {
                this.setState({ "count": result.count, 'records': result.data })
            }
        } catch (err) { }


    }
    render() {
        const {loader} = this.state
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Wallet Monitor</h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                total_record={this.state.records.length}
                                loading={loader}

                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}



export default (Userbalance);

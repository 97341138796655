// import package
import React, { Component, Fragment } from "react";
import { Modal, Button } from "react-bootstrap";

// import action
import {
  approveCoinWithdraw,
  rejectCoinWithdraw,
  trmwallet,
} from "../../actions/walletAction";

// import lib
import { toastAlert } from "../../lib/toastAlert";

class CoinWithdrawModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      rejectloader: false,
      confirmloader: false,
    };
  }

  rejectSubmit = async () => {
    const { record, fetchData } = this.props;
    this.setState({ rejectloader: true, loader: true });
    try {
      const { status, loading, message } = await rejectCoinWithdraw(record._id);
      this.setState({
        rejectloader: loading,
        loader: loading,
        errors: {},
        reason: "",
      });
      if (status == "success") {
        fetchData();
        this.handleClose();
        toastAlert("success", message, "coinwithdraw");
      } else {
        toastAlert("error", message, "coinwithdraw");
      }
    } catch (err) {}
  };

  confirmSubmit = async () => {
    const { record, fetchData } = this.props;
    this.setState({ confirmloader: true, loader: true });
    try {
      const { status, loading, message } = await approveCoinWithdraw(
        record._id
      );
      this.setState({ confirmloader: false, loader: false });
      if (status == "success") {
        fetchData();
        this.setState({ confirmloader: true, loader: true });
        this.handleClose();
        toastAlert("success", message, "coinwithdraw");
      } else {
        toastAlert("error", message, "coinwithdraw");
      }
    } catch (err) {}
  };

  handleClose = () => {
    const { onHide } = this.props;
    onHide();
  };

  handleTRMWallet = async () => {
    const { record, fetchData } = this.props;
    this.setState({ loader: true });

    try {
      const { status, loading, message, entities, riskindicators } =
        await trmwallet(record._id);
      this.setState({ loader: loading });
      if (status == "success") {
        fetchData();
        this.handleClose();
        this.setState({ trmmodal: true });
        if (riskindicators.length == 0) {
          toastAlert("success", "No Risk Indicator Found", "coinwithdraw");
        } else {
          this.setState({ riskindicators: riskindicators });
          this.setState({ entities: entities });
          console.log(
            JSON.stringify(riskindicators),
            "riskindicatorsriskindicators"
          );
          //   toastAlert(
          //     "success",
          //     JSON.stringify(entities) + JSON.stringify(riskindicators),
          //     "coinwithdraw"
          //   );
        }
      } else {
        toastAlert("error", "Something went wrong", "coinwithdraw");
      }
    } catch (err) {}
  };

  handleClosetrm = () => {
    this.setState({ trmmodal: false });
    this.setState({ riskindicators: [] });
  };

  render() {
    const { rejectloader, confirmloader, loader } = this.state;
    const { isShow, record } = this.props;
    const trmmodal = this.state.trmmodal;
    const riskindicators = this.state.riskindicators;

    return (
      <>
        {" "}
        <Modal
          show={trmmodal}
          onHide={this.handleClosetrm}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Wallet Screen Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-6">
              <table
                className="table table-bordered table-striped"
                id="as-react-datatable"
              >
                <thead className="">
                  <tr>
                    <th className="sortable  text-left" width="">
                      Category
                    </th>
                    <th className="sortable  text-left" width="">
                      RiskScoreLevel
                    </th>
                    <th className="sortable  text-left" width="">
                      RiskScoreLevelLabel
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {riskindicators &&
                    riskindicators.map((data, index) => (
                      <tr>
                        <td className="created_date">{data.category}</td>
                        <td className="status">
                          {data.categoryRiskScoreLevel}
                        </td>
                        <td className="action">
                          {data.categoryRiskScoreLevelLabel}
                        </td>
                      </tr>
                    ))}
                  {riskindicators && riskindicators.length == 0 && (
                    <tr>
                      <td colspan="4" className="action">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={isShow}
          size="lg"
          onHide={this.handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="popUpSpace">
              {record.status == "completed" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="answer">Transaction ID</label>
                  </div>
                  <div className="col-md-9">{record.txid}</div>
                </div>
              )}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="answer">To address</label>
                </div>
                <div className="col-md-9">{record.toAddress}</div>
              </div>

              {record.coin == "XRP" && (
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="answer">Tag id/Memo</label>
                  </div>
                  <div className="col-md-9">{record.destTag}</div>
                </div>
              )}

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="answer">Transfer Currency</label>
                </div>
                <div className="col-md-9">{record.coin}</div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="answer">Transfer Amount</label>
                </div>
                <div className="col-md-9">{record.actualAmount}</div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="answer">Transaction Hash</label>
                </div>
                <div className="col-md-9">{record.txid}</div>
              </div>
              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="answer">Commission Fee(%)</label>
                </div>
                <div className="col-md-9">{record.commissionFee}</div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="answer">Total Amount</label>
                </div>
                <div className="col-md-9">{record.amount}</div>
              </div>

              <div className="row mt-2">
                <div className="col-md-3">
                  <label htmlFor="answer">Status</label>
                </div>
                <div className="col-md-9">{record.status}</div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={this.handleTRMWallet}
              variant="success btnDefaultNew"
              disabled={loader}
            >
              TRM Wallet Screen
            </Button>
          </Modal.Footer>

          {record.status == "pending" && (
            <Modal.Footer>
              <Button
                variant="danger btnDefaultNewBlue"
                onClick={this.rejectSubmit}
                disabled={loader}
              >
                {rejectloader && <i class="fa fa-spinner fa-spin"></i>} Reject
              </Button>
              <Button
                onClick={this.confirmSubmit}
                variant="success btnDefaultNew"
                disabled={loader}
              >
                {confirmloader && <i class="fa fa-spinner fa-spin"></i>} Confirm
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </>
    );
  }
}

export default CoinWithdrawModal;

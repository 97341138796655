// import config
import axios from "../config/axios";

export const getDepositList = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/depositList`,
      method: "get",
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getWithdrawList = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/withdrawList`,
      method: "get",
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const approveCoinWithdraw = async (id) => {
  try {
    const respData = await axios({
      url: `/adminapi/coinWithdraw/approve/${id}`,
      method: "get",
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const rejectCoinWithdraw = async (id) => {
  try {
    const respData = await axios({
      url: `/adminapi/coinWithdraw/reject/${id}`,
      method: "get",
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const approveFiatWithdraw = async (id) => {
  try {
    const respData = await axios({
      url: `/adminapi/fiatWithdraw/approve/${id}`,
      method: "get",
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const rejectFiatWithdraw = async (id) => {
  try {
    const respData = await axios({
      url: `/adminapi/fiatWithdraw/reject/${id}`,
      method: "get",
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const trmwallet = async (id) => {
  try {
    const respData = await axios({
      url: `/adminapi/coinWithdraw/trmwalletscreen/${id}`,
      method: "get",
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      riskindicators: respData.data.riskindicators,
      entities: respData.data.entities,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
    };
  }
};

export const approveFiatDeposit = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/fiatDeposit/approve`,
      method: "post",
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const fundList = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/fundList`,
      method: "get",
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const getrefferalList = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/refferrallist`,
      method: "get",
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};


export const walletMonitor = async (data) => {
  try {
    let respData = await axios({
      'method': 'get',
      'url': `/adminapi/wallet-monitor`,
      'params': data
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result
    }
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors
    }
  }
}

// import lib
import isEmpty from "./isEmpty";

Number.prototype.toFixedNoRounding = function (n) {
    const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
    const a = this.toString().match(reg)[0];
    const dot = a.indexOf(".");
    if (dot === -1) {
        // integer, insert decimal dot and pad up zeros
        return a + "." + "0".repeat(n);
    }
    const b = n - (a.length - dot) + 1;
    return b > 0 ? a + "0".repeat(b) : a;
};

export const toFixed = (item, type = 2) => {
    try {
        if (!isEmpty(item) && !isNaN(item)) {
            item = parseFloat(item);
            return item.toFixed(type);
        }
        return "";
    } catch (err) {
        return "";
    }
};

export const currencyFormat = (item) => {
    try {
        if (!isEmpty(item) && !isNaN(item)) {
            item = item.toString();
            let splitValue = item.split(".");
            return splitValue[1]
                ? `${splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${splitValue[1]
                }`
                : splitValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "";
    } catch (err) {
        return "";
    }
};

export const randomstring = (x) => {
    try {
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < charactersLength; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    } catch (err) {
        return "";
    }
};

export const longNumbers = (x, n) => {
    try {
        if (x < 0.000001) {
            x = 0.0;
        } else if (x > 100) {
            x = x.toFixedNoRounding(2);
            return x;
        }
        return x.toFixedNoRounding(n);
    } catch (err) {
        return "";
    }
};
export const toFixedDown = (item, type = 2) => {
    try {
        if (!isEmpty(item) && !isNaN(item)) {
            item = parseFloat(item);
            let decReg = new RegExp("(\\d+\\.\\d{" + type + "})(\\d)"),
                m = item.toString().match(decReg);
            return m ? parseFloat(m[1]) : item.valueOf();
        }
        return "";
    } catch (err) {
        return "";
    }
};

import axios from 'axios'

export const addBonus = async (data) => {
    try {
        let respData = await axios({
            url: '/adminapi/add-bonus',
            method: 'post',
            data
        })
        return {
            status: 'success',
            message: respData.data.message,
        }
    } catch (err) {
        return {
            status: 'failed',
            message: err.response.data.message,
        }
    }
}
export const updateSetting = async (data) => {
    try {
        let respData = await axios({
            url: '/adminapi/update-bonus',
            method: 'post',
            data
        })
        return {
            status: 'success',
            message: respData.data.message,
        }
    } catch (err) {
        return {
            status: 'failed',
            message: err.response.data.message,
        }
    }
}
export const bonusHis = async (data) => {
    try {
        let respData = await axios({
            url: '/adminapi/bonus-history',
            method: 'get',
            params: data
        })
        return {
            status: 'success',
            result: respData.data.result,
        }
    } catch (err) {
        return {
            status: 'failed',
            message: err.response.data.message,
        }
    }
}

export const getSettings = async () => {
    try {
        const respData = await axios({
            url: `/adminapi/get-bonus`,
            method: "get",
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
        };
    }
};
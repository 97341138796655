import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Link } from "react-router-dom";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons/faUserAlt";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { DashboardHist,getDashboardDet } from '../../actions/dashboardAction';
import LineChart from '../partials/dashboard/lineChart'
import isEmpty from '../../lib/isEmpty'


//component
import OrderHist from '../../components/pages/DashboardOrderHistory'
import TradeHist from '../../components/pages/DashboardTradeHistory'
import DepositHist from '../../components/pages/DashboardDepositHistory'
import WithdrawHist from '../../components/pages/DashboardWithdrawHistory'

var CanvasJSReact = require('../../canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;




class Dashboard extends Component {

    constructor() {
        super()
        this.state = {
            records: '',
            countResult:{}
        }
        this.GetHistory = this.GetHistory.bind(this)
    }

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    async GetHistory() {
        let { status, result } = await DashboardHist()
        if (status) {
            this.setState({ records: result })
        }
    }

   async fetchDashboardDet(){
    let { status, result } = await getDashboardDet()
    if (status) {
        this.setState({ countResult: result })
    }
   }
    componentDidMount() {
        this.GetHistory();
        this.fetchDashboardDet();
    }

    render() {
        const { records ,countResult} = this.state
        //const { user } = this.props.auth;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <h3 className="mt-2 text-secondary">Dashboard </h3>
                            <div className="row px-0 px-md-2">

                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <div className="card text-white shadow-lg" style={{ backgroundColor: "cornflowerblue" }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Users </h5>
                                            <p className="card-text">Total number of Users { countResult&&countResult.userCount}</p>
                                            <Link to="/users" className="btn btn-light"><FontAwesomeIcon className="text-primary" icon={faUserAlt} /> Go to Users</Link>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <div className="card text-white shadow-lg" style={{ backgroundColor: "cadetblue" }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Spot orders</h5>
                                            <p className="card-text"> Total number of orders  {countResult&&countResult.spotCount}</p>
                                            <Link to="/orderhistory" className="btn btn-light"><FontAwesomeIcon className="text-primary" icon={faUserAlt} /> Go to Order History</Link>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <div className="card text-white shadow-lg" style={{ backgroundColor: "cadetblue" }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Deposit </h5>
                                            <p className="card-text"> Total number of Deposit  {countResult&&countResult.depositCount}</p>
                                            <Link to="/deposit" className="btn btn-light"><FontAwesomeIcon className="text-primary" icon={faUserAlt} /> Go to Deposit History </Link>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-sm-3 p-sm-2 mb-4 mb-sm-0">
                                    <div className="card text-white shadow-lg" style={{ backgroundColor: "cornflowerblue" }}>
                                        <div className="card-body">
                                            <h5 className="card-title">Withdraw</h5>
                                            <p className="card-text"> Total number of withdraw  {countResult&&countResult.withdrawCount} </p>
                                            <Link to="/withdraw" className="btn btn-light"><FontAwesomeIcon className="text-primary" icon={faUserAlt} /> Go to Withdraw History</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            < LineChart />  
                            <div className='container'>
                                <div className="row ">
                                                         
                                </div>
                            </div>


                            {/* <div className='container'>
                                <div className="row ">
                                    <div className="col-md-6">
                                        <Cad>
                                            <Card.Body>

                                                <OrderHist
                                                    record={records.orderHistory}
                                                />


                                            </Card.Body>

                                        </Card>
                                    </div>






                                    <div className="col-md-6">
                                        <Card>
                                            <Card.Body>


                                                <TradeHist
                                                    record={records.trdeHistory}
                                                />

                                            </Card.Body>

                                        </Card>
                                    </div>
                                </div>
                            </div> */}




                            {/* <div className='container'>
                                <div className="row ">
                                    <div className="col-md-6">
                                        <Card>
                                            <Card.Body>

                                                <DepositHist
                                                    record={records.depositHistory}
                                                />


                                            </Card.Body>

                                        </Card>
                                    </div>






                                    <div className="col-md-6">
                                        <Card>
                                            <Card.Body>


                                                <WithdrawHist
                                                    record={records.withdrawHistory}
                                                />

                                            </Card.Body>

                                        </Card>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Dashboard);

import React, { Component } from 'react'
import { Modal, Form } from "react-bootstrap";
import classnames from "classnames";
import { editrefferaltier } from "../../actions/airdropAction"
// import lib
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
    _id: "",
    percentage: "",
};



class RefferralUpdate extends Component {
    constructor() {
        super();
        this.state = {
            loader: false,
            formValue: initialFormValue,
            errors: {},
        };
    }



    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { formValue } = this.state;
            const { fetchData } = this.props;
            let reqData = formValue;
            let { status, loading, result, error, message } = await editrefferaltier(
                reqData
            );
            if (status == "success") {
                fetchData();
                toastAlert("success", message, "addTemplate");
                this.handleClose();
            } else {
                if (error) {
                    this.setState({ errors: error });
                }
                toastAlert("error", message, "addSpotPair");
            }
        } catch (err) {
            console.log(err, 'error')
        }
    };



    componentWillReceiveProps(nextProps) {
        const { record } = nextProps;
        if (record) {
            this.setState({
                formValue: {
                    _id: record._id,
                    percentage: record.percentage,
                },
            });
        }
    }




    handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        this.setState({ formValue: formData });
    };


    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        this.setState({ formValue: initialFormValue, errors: {} });
    };


    render() {

        const { _id, percentage } = this.state.formValue;
        const { loader, errors } = this.state;
        const { isShow } = this.props;
        return (

            <>
                <div>
                    <Modal
                        show={isShow}
                        onHide={this.handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                        scrollable={true}
                    >
                        <Modal.Header closeButton>
                            <h4 className="modal-title">Update Refferral</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <form id="add-template">
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="taker_fees"> percentage(%)</label>
                                    </div>
                                    <div className="col-md-9">
                                        <input
                                            onChange={this.handleChange}
                                            value={percentage}
                                            name="percentage"
                                            error={errors.percentage}
                                            id="percentage"
                                            type="text"
                                            className={classnames("form-control", {
                                                invalid: errors.percentage,
                                            })}
                                        />
                                        <span className="text-danger">
                                            {errors.percentage}
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={this.handleClose}
                            >
                                Close
                            </button>
                            <button
                                onClick={this.handleSubmit}
                                className="btn btn-primary">
                                {loader && <i class="fas fa-spinner fa-spin"></i>}Update Refferral
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>

            </>
        )
    }
}
export default RefferralUpdate;


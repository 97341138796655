import React, { Component } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { getTopTraders } from "../../actions/copyTradeAction";

class TopTraders extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "user",
        text: "User",
        className: "identifier",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record && record.userName;
        },
      },
      {
        key: "history.total",
        text: "Total",
        className: "approval",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record && record.history.total;
        },
      },
      {
        key: "history.gain",
        text: "Gain",
        className: "approval",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record && record.history.gain;
        },
      },
      {
        key: "history.loss",
        text: "Loss",
        className: "approval",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record && record.history.loss;
        },
      },
      {
        key: "history.AccumPLRatio",
        text: "P/L Ratio",
        className: "approval",
        align: "left",
        sortable: true,
        cell: (record) => {
          if (record.history.AccumPLRatio > 0)
            return (
              <span className="text-success">
                {record.history.AccumPLRatio} %
              </span>
            );
          else
            return (
              <span className="text-danger">
                {record.history.AccumPLRatio} %
              </span>
            );
        },
      },
      {
        key: "curFollow",
        text: "Total Followers",
        className: "followers",
        align: "left",
        sortable: true,
        cell: (record) => {
          return record && record.followers;
        },
      },
      {
        key: "lastTradeDate",
        text: "Last Traded Date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return moment(record.lastTradeDate).format("YYYY-MM-DD");
        },
      },
    ];

    this.state = {
      totalCount: 0,
      records: [],
      offset: 0,
      pageSize: 10,
      sortOrder: {
        column: "history.AccumPLRatio",
        order: "desc",
      },
      page: 1,
      limit: 10,
      search: "",
      loader: false,
    };

    this.config = {
      page_size: this.state.pageSize,
      length_menu: [10, 20, 50],
      filename: "Cms",
      no_data_text: "No Traders found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      key_column: "_id",
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      pagination: "advance",
      show_info: true,
    };

    this.getData = this.getData.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getData(reqData);
  }
  async getData(data) {
    this.setState({ loader: true });
    let { status, loading, result, totalCount } = await getTopTraders(data);
    this.setState({ loader: false });
    if (status) {
      this.setState({ records: result, totalCount: totalCount });
    }
  }
  handlePagination(index) {
    let { page, limit } = this.state;
    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getData(reqData);
    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }
  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Top Traders List</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                onChange={this.handlePagination}
                loading={this.state.loading}
                total_record={this.state.totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopTraders;

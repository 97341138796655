// import lib
import isEmpty from "../../../lib/isEmpty";

const validation = (value) => {
  let errors = {};

  if (isEmpty(value.coinId)) {
    errors.coinId = "Coin field is Required";
  }

  if (isEmpty(value.side)) {
    errors.side = "Side field is Required";
  } else if (!["buy", "sell"].includes(value.side)) {
    errors.side = "invalid side";
  }

  if (isEmpty(value.startQuantity)) {
    errors.startQuantity = "Start Quantity field is Required";
  } else if (isNaN(value.startQuantity)) {
    errors.startQuantity = "Start Quantity only numeric value";
  } else if (parseFloat(value.startQuantity) < 0) {
    errors.startQuantity = "Start Quantity only positive numeric value";
  }

  if (isEmpty(value.endQuantity)) {
    errors.endQuantity = "End Quantity field is Required";
  } else if (isNaN(value.endQuantity)) {
    errors.endQuantity = "End Quantity only numeric value";
  } else if (parseFloat(value.endQuantity) < 0) {
    errors.endQuantity = "End Quantity only positive numeric value";
  } else if (
    !isEmpty(value.endQuantity) &&
    value.startQuantity >= value.endQuantity
  ) {
    errors.endQuantity = "End Quantity must higher than Start Quantity";
  }
  return errors;
};

export default validation;

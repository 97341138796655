import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { newsletteremail } from "../../actions/userActions";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import { withRouter } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import Multiselect from "multiselect-dropdown-react";
import {
  addfees,
  getfees,
  addfeesreferral,
  getfees_referral,
} from "../../actions/airdropAction";
import { Modal, Form } from "react-bootstrap";
import { currencyList, getCurrency } from "../../actions/currency";

const url = keys.baseUrl;
class FeesOwnToken extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenamount: "",
      errors: {},
      firstCurrencyId: "",
      firstCurrencyId_ref: "",
      currencyOptions: [],
      formValue: "",
    };
    console.log(this.state, "state");
  }

  componentDidMount() {
    this.getCurrencyData();
    this.getData();
    this.getData_referral();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps, "nextprops");
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.profile !== undefined &&
      nextProps.auth.profile.data !== undefined &&
      nextProps.auth.profile.data.message !== undefined
    ) {
      toast(nextProps.auth.profile.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      nextProps.auth.profile = undefined;
    }
  }

  result = (params) => {
    console.log(params);
    this.setState({
      email1: params,
    });

    console.log(this.setState, "emailssss");
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  async getData() {
    const { result, status } = await getfees();
    var currencyarray = [];
    if (status == "success") {
      let formData = {  //firstCurrencyId
        firstCurrencyId_ref: result.data.currencyId._id,
      };

      this.setState({ formValue: formData });
      this.setState({ tokenamount: result.data.percentage });
    }
  }

  async getData_referral() {
    const { result, status } = await getfees_referral();
    var currencyarray = [];

    if (result && status == "success") {
      let formData = {
        firstCurrencyId_ref: result.data.currencyId._id,
      };

      this.setState({ formValue: formData });
      this.setState({ percentage: result.data.percentage });
      this.setState({ usdtamount: result.data.usdtamount });
    }
  }

  async getCurrencyData() {
    const { result, status } = await getCurrency();


    if (status == "success") {
      var currencyarray = [];
      result.map((item, i) => {
        const id = item._id;
        const label = item.coin;
        const obj = { value: id, label: label, type: item.type };
        currencyarray.push(obj);
        this.setState({ currencyOptions: currencyarray });
      });
    }
  }

  onemailsend = async (e) => {
    //  if (window.confirm("Are you sure to send") == true) {
    e.preventDefault();
    const { formValue } = this.state;
    console.log(formValue, "formValueformValue");

    if (this.state.tokenamount >= 0 && formValue) {
      const data = {
        tokenamount: this.state.tokenamount,

        currencyId: formValue,
      };

      const { status, loading, message, error } = await addfees(data);

      if (status == "success") {
        toast("Token updated successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        this.props.history.push("/feesowntoken");
      }
    } else {
      toast("Please Fill all the fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    //}
  };

  onreferralsend = async (e) => {
    //  if (window.confirm("Are you sure to send") == true) {
    e.preventDefault();
    const { formValue } = this.state;

    if (this.state.usdtamount > 0 && this.state.percentage > 0 && formValue) {
      const data = {
        percentage: this.state.percentage,
        usdtamount: this.state.usdtamount,
        currencyId: formValue,
      };

      const { status, loading, message, error } = await addfeesreferral(data);

      if (status == "success") {
        toast("Referral updated successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        this.props.history.push("/feesowntoken");
      }
    } else {
      toast("Please Fill all the fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    //}
  };

  handleChange = (e) => {
    e.preventDefault();
    let { name, id, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [id]: value } };
    this.setState({ formValue: formData });
  };

  render() {
    const { errors, currencyOptions } = this.state;
    const { formValue } = this.state;

    const { firstCurrencyId, firstCurrencyId_ref } = this.state.formValue;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Referal Settings</h3>
              {/* <form noValidate onSubmit={this.onemailsend} id="send-email">
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="identifier">Token : </label>
                  </div>
                  <div className="col-md-9">
                    <Form.Control
                      as="select"
                      custom
                      name={"firstCurrencyId"}
                      id={"firstCurrencyId"}
                      value={firstCurrencyId}
                      onChange={this.handleChange}
                    >
                      <option value={""}>{"Select Currency"}</option>
                      {currencyOptions &&
                        currencyOptions.length > 0 &&
                        currencyOptions.map((item, key) => {
                          if (item.label == "MUDRA") {
                            return (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            );
                          }
                        })}
                    </Form.Control>
                    <span className="text-danger">
                      {errors.firstCurrencyId}
                    </span>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="content">Token Fees (Percentage) : </label>
                  </div>

                  <div className="col-md-9">
                    <input
                      onChange={this.onChange}
                      value={this.state.tokenamount}
                      id="tokenamount"
                      type="text"
                      error={errors.categoryNameerr}
                      className={classnames("form-control", {
                        invalid: errors.tokenamount,
                      })}
                    />
                    <span style={{ color: "red" }}>
                      {errors.tokenamounterr}
                    </span>
                    <span className="text-danger">{errors.categoryNameerr}</span>
                  </div>
                </div>
              </form> */}
              <br />
              {/* <div className="modal-footer">
                <button
                  form="send-email"
                  type="submit"
                  className="btn btn-primary"
                >
                  Save Fee
                </button>
              </div> */}
            </div>

            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">
                Referral Commission Settings
              </h3>
              <form noValidate onSubmit={this.onreferralsend} id="refer-email">
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="identifier">Token : </label>
                  </div>
                  <div className="col-md-9">
                    <Form.Control
                      as="select"
                      custom
                      name={"firstCurrencyId_ref"}
                      id={"firstCurrencyId_ref"}
                      value={firstCurrencyId_ref}
                      onChange={this.handleChange}
                    >
                      <option value={""}>{"Select Currency"}</option>
                      {currencyOptions &&
                        currencyOptions.length > 0 &&
                        currencyOptions.map((item, key) => {
                          if (item.type == "token") {
                            return (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            );
                          }
                        })}
                    </Form.Control>
                    <span className="text-danger">
                      {errors.firstCurrencyId}
                    </span>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="content">USDT Amount: </label>
                  </div>

                  <div className="col-md-9">
                    <input
                      onChange={this.onChange}
                      value={this.state.usdtamount}
                      id="usdtamount"
                      type="text"
                      // error={errors.categoryNameerr}
                      className={classnames("form-control", {
                        invalid: errors.usdtamount,
                      })}
                    />
                    <span style={{ color: "red" }}>{errors.usdtamounterr}</span>
                    {/*<span className="text-danger">{errors.categoryNameerr}</span> */}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="content">Referral Percentage : </label>
                  </div>

                  <div className="col-md-9">
                    <input
                      onChange={this.onChange}
                      value={this.state.percentage}
                      id="percentage"
                      type="text"
                      // error={errors.categoryNameerr}
                      className={classnames("form-control", {
                        invalid: errors.percentage,
                      })}
                    />
                    <span style={{ color: "red" }}>{errors.percentage}</span>
                    {/*<span className="text-danger">{errors.categoryNameerr}</span> */}
                  </div>
                </div>
              </form>
              <br />
              <div className="modal-footer">
                <button
                  form="refer-email"
                  type="submit"
                  className="btn btn-primary"
                >
                  Save Referral
                </button>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

FeesOwnToken.propTypes = {
  newsletteremail: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { newsletteremail })(
  withRouter(FeesOwnToken)
);

import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import ReactDatatable from "@ashvin27/react-datatable";
import { toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RefferralUpdateModal from "../partials/RefferralUpdateModal";
import { getrefferaltier } from "../../actions/airdropAction";
import isEmpty from '../../lib/isEmpty'
class Referral extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "tier",
        text: "Tier",
        className: "tier",
        align: "left",
        sortable: true,
        width: 200,
        cell: (record) => {
          return <Fragment>Tier {record.tier}</Fragment>;
        },
      },

      {
        key: "percentage",
        text: "Percentage (%)",
        className: "percentage",
        align: "left",
        sortable: true,
        width: 200,
      },

      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-currency-modal"
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Referral",
      no_data_text: "No Referral found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      editFormModal: false,
      loader: false,
      records: [],
    };

    this.state = {
      currentRecord: {
        percentage: "",
        tier: "",
      },
      page: 1,
      limit: 10,
      errors: {},
    };

    this.getrefferaltierlist = this.getrefferaltierlist.bind(this);
    // this.handlePagination = this.handlePagination.bind(this);
    // this.handleCloseAddForm = this.handleCloseAddForm.bind(this);
    this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
    this.refetch = this.refetch.bind(this);
    this.handlePagination = this.handlePagination.bind(this)
  }

  componentDidMount() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getrefferaltierlist(reqData);
  }
  async getrefferaltierlist(reqData) {
    this.setState({ loader: true });
    try {
      const { status, loading, message, result } = await getrefferaltier();
      this.setState({ loader: false });
      if (status == "success") {
        let arr = []
        let data = result.data.find(item => item.percentage == reqData.search)
        if (!isEmpty(data)) {
          arr.push(data)
          this.setState({ records: arr });
        } else {
          this.setState({ records: result.data });
        }
      }
    } catch (err) {
      console.log(err, "errors");
    }
  }

  editRecord(record) {
    this.setState({
      editFormModal: true,
      editRecord: record,
    });
  }

  refetch() {
    const { page, limit } = this.state;
    let reqData = {
      page,
      limit,
    };
    this.getrefferaltierlist(reqData);
  }

  handleCloseEditForm() {
    this.setState({ editFormModal: false, editRecord: {} });
  }
  handlePagination(index) {

    let reqData = {
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    };
    this.getrefferaltierlist(reqData);

    this.setState({
      page: index.page_number,
      limit: index.page_size,
      search: index.filter_value,
    });
  }

  render() {
    const { editRecord, editFormModal, loader, count } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />

          <RefferralUpdateModal
            isShow={editFormModal}
            onHide={this.handleCloseEditForm}
            fetchData={this.refetch}
            record={editRecord}
          />

          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Refferral Tier List</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={count}
                loading={loader}
                onChange={this.handlePagination}

              />
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

export default Referral;

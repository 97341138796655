// import action
import { decodeJwt } from "./jsonWebToken";

// import config
import axios from "../config/axios";
import Config from "../config/index";

// import lib
import { setAuthToken } from "../lib/localStorage";

export const login = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/login`,
      data,
    });
    if (respData.data.status == "TWO_FA") {
      return {
        status: "TWO_FA",
        loading: false,
        message: respData.data.message,
      };
    }

    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const list = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/subAdmin`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const creatAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/sub-admin`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const EditAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/edit-admin`,
      data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getGeoInfoData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${Config.getGeoInfo}`,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const loginHisPagination = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/login-history`,
      params: data,
    });

    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getAdminProfile = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/adminProfile`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const profileUpdatae = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/adminapi/adminProfile`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.messages,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      errors: err.response.data.errors,
    };
  }
};

export const changePassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/adminProfile`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.messages,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      errors: err.response.data.errors,
    };
  }
};

export const getNotification = async (data) => {
  try {
    const respData = await axios({
      url: `/adminapi/getNotification`,
      method: "get",
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      count: respData.data.count,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
    };
  }
};

export const clearAllNotification = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/getNotification`,
    });
    return {
      status: "success",
      loading: false,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getPassBookList = async (data) => {
  try {
    let respData = await axios({
      'method': 'get',
      'url': `/adminapi/get-passbook-list`,
      'params': data
    });

    return {
      status: respData.data.status,
      count: respData.data.count,
      result: respData.data.result
    }

  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors
    }
  }
}

export const smsLog = async (data) => {
  try {
    let respData = await axios({
      'method': 'get',
      'url': `/adminapi/smsLog`,
      'params': data
    });

    return {
      status: respData.data.status,
      count: respData.data.count,
      result: respData.data.result
    }

  } catch (err) {
    return {
      status: false,
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors
    }
  }
}
export const getImg = async (data) => {
  try {
    console.log(`${Config.API_URL}/support/${data}`)
    let respData = await axios({
      method: "get",
      url: `${Config.API_URL}/support/${data}`,
      headers: { type: "admin" },
      responseType: 'arraybuffer',
    });
    let base64 = "data:" + respData.headers["content-type"] + ";base64," + Buffer.from(respData.data).toString('base64');
    return {
      status: "success",
      loading: false,
      result: base64,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
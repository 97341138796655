import axios from 'axios'


export const ProfitManagementAction = async (data)=>{
    try{
        const respData = await axios({
            'url':'/adminapi/fetch-profit',
            'method':'get',
            'params':data
        })
        return {
            status:"success",
            loading:false,
            message:respData.data.message,
            result:respData.data.result
        }
    }
    catch(err){
          return{
              status:'failed',
              loading:false,
              message:err.response.data.message
          }
    }
}
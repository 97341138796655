import React from 'react'
import classnames from "classnames";

//import action

import { getChartData } from '../../../actions/dashboardAction'

import CanvasJSReact from './assets/canvasjs.react'

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class LineChart extends React.Component {
	constructor() {
		super();
		this.state = {
			record: [],

			title: {
				text: "Last 30 Days Profit"
			},
			axisY: {
				title: "Profit(USD)",
				includeZero: false,
				suffix: ""
			},
			axisX: {
				title: "Date(last 30 days)",
				prefix: "",
				interval: 1
			},
			selectFillter: "last30"
		};
	}

	ChartData = async (params) => {

		const { result, status } = await getChartData(params);
		if (status == "success") {
			this.setState({ record: result })

		}
	}

	fillterChnage = (e) => {

		const { value, name } = e.target;
		let title = {}, axisY = {}, axisX = {}, selectFillter = "";
		if (value == "last30") {

			title["text"] = "Last 30 Days Profit";
			axisY["title"] = "Profit(USD)"
			axisY["includeZero"] = false;
			axisY["suffix"] = "";
			axisX["title"] = "Date(last 30 days)"
			axisX["prefix"] = "";
			axisX["interval"] = 1;
			selectFillter = "last30"
		}
		if (value == "yearOfWeek") {

			title["text"] = " Profit in Total Weeks in current Year ";
			axisY["title"] = "Profit(USD)"
			axisY["includeZero"] = false;
			axisY["suffix"] = "";

			axisX["title"] = "Total Weeks  in current Year"
			axisX["prefix"] = "";
			axisX["interval"] = 1;
			selectFillter = "yearOfWeek"
		}
		if (value == "yearOfMonth") {

			title["text"] = "Profit per  Month in current Year";
			axisY["title"] = "Profit(USD)"
			axisY["includeZero"] = false;
			axisY["suffix"] = "";
			axisX["title"] = "Month in current Year"
			axisX["prefix"] = "";
			axisX["interval"] = 1;
			selectFillter = "yearOfMonth"

		}

		this.setState({ title, axisY, axisX, selectFillter });
		let params = {
			type: value,
		}

		this.ChartData(params)
	}
	componentDidMount() {

		let params = {
			type: this.state.selectFillter,

		}

		this.ChartData(params)
	}

	render() {

		const { record, title, axisY, axisX, selectFillter } = this.state;

		const options = {

			title: title,
			axisY: axisY,
			axisX: axisX,
			data: [{
				type: "line",
				dataPoints: record

			}]
		}


		return (
			<div>
				<h5>Line Chart </h5>
				{
					record&&record.length>0 ?
					<>
					<label for="sel1" class="form-label">Fillter by:</label>
				<select class="form-select" id="sel1" name="sellist1" value={selectFillter} onChange={this.fillterChnage} >
					<option value={"last30"} >Day(last 30 day)</option>
					<option value={"yearOfWeek"} >Week </option>
					<option value={"yearOfMonth"} >Month</option>
				</select>
				<CanvasJSChart options={options}
				/* onRef = {ref => this.chart = ref} */
				/>
					</>
					:
					<p> No records </p>
				}
				
			</div>
		);
	}
}




export default LineChart; 

import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { traderList, traderStatus } from "../../actions/copyTradeAction";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import { momentFormat } from "../../lib/dateTimeHelper";
import isEmpty from "../../lib/isEmpty";

class CpFutureTrader extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "userCode",
        text: "User",
        className: "identifier",
        align: "left",
        sortable: true,
      },
      {
        key: "status",
        text: "Status",
        className: "approval",
        align: "left",
        sortable: true,
      },
      {
        key: "createdAt",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return momentFormat(record.createdAt, "YYYY-MM-DD, HH:mm:ss");
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 200,
        align: "left",
        sortable: false,
        cell: (record) => {
          if (record.status === "pending") {
            return (
              <Fragment>
                <button
                  className="btn btn-primary btn-sm mr-1"
                  onClick={() => this.handleStatus(record, "approved")}
                >
                  Accept
                </button>
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => this.handleStatus(record, "rejected")}
                >
                  Reject
                </button>
              </Fragment>
            );
          }
        },
      },
    ];

    this.state = {
      page: 1,
      limit: 10,
      totalCount: 0,
      records: [],
      sortOrder: {
        column: "createdAt",
        order: "desc",
      },
      loader: false,
    };

    this.config = {
      page_size: this.state.limit,
      length_menu: [10, 20, 50],
      filename: "Cms",
      no_data_text: "No Application found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (filter = "") => {
    try {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let reqData = {
        // timezone,
        // page: 'application',
        // sortOrder: this.state.sortOrder,
        // filter,
        limit: this.state.limit,
        page: this.state.page,
      };
      this.setState({ loading: true });
      let { status, loading, result, totalCount } = await traderList(reqData);
      this.setState({ loader: false });
      if (status) {
        if (!isEmpty(filter)) {
          let data = result.filter(
            (item) =>
              item.userCode == filter ||
              item.status == filter ||
              momentFormat(item.createdAt, "YYYY-MM-DD, HH:mm:ss") == filter
          );
          this.setState({ records: data, totalCount: totalCount });
        } else {
          this.setState({ records: result, totalCount: totalCount });
        }
      }
    } catch (err) {}
  };

  handleStatus = async (record, action) => {
    try {
      let data = {
        id: record._id,
        action: action,
      };
      this.setState({ loader: true });
      let { status, loading, message } = await traderStatus(data);
      this.setState({ loader: false });
      if (status) {
        this.getData();
        toastAlert("success", message, "cpFutureTrader");
      } else {
        toastAlert("error", message, "cpFutureTrader");
      }
    } catch (err) {}
  };

  search = (data) => {
    let filter = data.filter_value;
    let sortOrder = data.sort_order ? data.sort_order : this.state.sortOrder;
    this.setState(
      {
        records: [],
        limit: data.page_size,
        page: data.page_number,
        sortOrder: sortOrder,
      },
      function () {
        this.getData(filter);
      }
    );
  };

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Copy Trader List</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                loading={this.state.loader}
                onChange={this.search}
                total_record={this.state.totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CpFutureTrader;

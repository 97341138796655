// import package
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

// import action
import { getCurrency } from "../../actions/currency";
import { usrDropdown } from '../../actions/userActions'
import { addBonus, updateSetting, getSettings } from '../../actions/bonusAction'

// import lib
import { toastAlert } from '../../lib/toastAlert';
import isEmpty from "is-empty";

const initialFormValue = {
    'userList': [],
    'amount': "",
    'volume': ""
}
class Bonus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currencyOption: [],
            userOption: [],
            selectedArr: [],
            formValue: initialFormValue,
        };
        this.fetchCurrency = this.fetchCurrency.bind(this);
        this.fetchUser = this.fetchUser.bind(this)
    }

    componentDidMount() {
        this.fetchCurrency()
        this.fetchUser()
        this.getBnsSetting()
    };

    async fetchCurrency() {
        try {
            const { status, result } = await getCurrency();
            if (status == "success") {
                this.setState({ currencyOption: result });
            }
        } catch (err) { }
    }

    async fetchUser() {
        try {
            const { status, result } = await usrDropdown();
            if (status == "success") {
                let users = []
                result && result.length > 0 && result.map((item) => {
                    users.push({ label: item.email, value: item._id });
                });

                this.setState({ userOption: users });
            }
        } catch (err) { }
    }
    getBnsSetting = async () => {
        let { result } = await getSettings()
        if (!isEmpty(result)) {
            let formData = { ...this.state.formValue, 'volume': result.xTimes };
            this.setState({ formValue: formData });
        }
    }
    handleChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let formData = { ...this.state.formValue, [name]: value };
        this.setState({ formValue: formData });
    };

    handleSelect = (selectedOption) => {
        let formData = this.state.formValue;
        if (selectedOption && selectedOption.length > 0) {
            formData["userList"] = selectedOption.map((el) => {
                return el.value;
            });
        } else {
            formData["userList"] = [];
        }
        this.setState({ 'selectedArr': selectedOption, 'formValue': formData })
    };

    onSubmit = async (e) => {
        e.preventDefault();
        try {
            let reqData = this.state.formValue
            let { status, message } = await addBonus(reqData)
            if (status == 'success') {
                toastAlert('success', message, 'bonus')
                const initial = {
                    'userList': [],
                    'amount': "",
                }
                this.setState({ 'formValue': initial, 'selectedArr': [] })
            } else {
                toastAlert('error', message, 'bonus')
            }
        } catch (err) { }
    }

    handeSubmit = async (e) => {
        e.preventDefault()
        try {
            let data = {
                volume: this.state.formValue.volume
            }
            let { status, message } = await updateSetting(data)
            if (status == 'success') {
                toastAlert('success', message, 'bonus')
            } else {
                toastAlert('error', message, 'bonus')
            }
        } catch (err) { }
    }

    render() {
        const { currencyOption, userOption, selectedArr } = this.state
        const { amount, volume } = this.state.formValue

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">

                            <h3 className="mt-2 text-secondary">Bonus settings</h3>
                            <form noValidate onSubmit={this.onSubmit} id="refer-email">



                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="content">Volume USDT(x) : </label>
                                    </div>

                                    <div className="col-md-9">
                                        <input
                                            onChange={this.handleChange}
                                            value={volume}
                                            name="volume"
                                            type="number"
                                        />
                                    </div>
                                </div>
                            </form>
                            <br />
                            <div className="modal-footer">
                                <button
                                    className="btn btn-primary"
                                    onClick={this.handeSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                        <div className="container-fluid">

                            <h3 className="mt-2 text-secondary">Bonus</h3>
                            <form noValidate onSubmit={this.onSubmit} id="refer-email">
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="content">Users : </label>
                                    </div>
                                    <div className="col-md-9">
                                        <MultiSelect
                                            className="custom-multi-select"
                                            options={userOption}
                                            value={selectedArr}
                                            onChange={this.handleSelect}
                                            labelledBy="Select"
                                            overrideStrings={{
                                                allItemsAreSelected: "All Emails are selected.",
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="content">Amount : </label>
                                    </div>

                                    <div className="col-md-9">
                                        <input
                                            onChange={this.handleChange}
                                            value={amount}
                                            name="amount"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </form>
                            <br />
                            <div className="modal-footer">
                                <button
                                    form="refer-email"
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Bonus
// import config
import axios from "../config/axios";

export const addPair = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/adminApi/p2pPair`,
            'data': data
        });

        return {
            status: 'success',
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const editPair = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/adminApi/p2pPair`,
            'data': data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const pairList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/p2pPair`,
            'params': data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const orderReport = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/p2p/orderReport`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}

export const getOrderReport = async (orderId) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/p2p/getOrderReport/${orderId}`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}

export const adminConversation = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/adminapi/p2p/adminConversation`,
            'data': data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            errors:err.response.data.error
        }
    }
}

export const disputeResolve = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/adminapi/p2p/disputeResolve`,
            'data': data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}

export const disputeList = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/adminapi/p2p/disputeList`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message
        }
    }
}
import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateProfile } from "../../actions/userActions";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import { withRouter } from "react-router-dom";

//import Actions

import { getAdminProfile, profileUpdatae } from "../../actions/admin";

//import lib
import { toastAlert } from "../../lib/toastAlert";

const initailValue = {
  name: "",
  email: "",
  phoneNumber: "",
};
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: initailValue,
      errors: {},
      loader: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const formValue = this.state.formValue;
    this.setState({ loader: true });
    const { result, status } = await getAdminProfile();
    this.setState({ loader: false });
    if (status == "success") {
      formValue.name = result.name;
      formValue.email = result.email;
      formValue.phoneNumber = result.phoneNumber;
      this.setState({ formValue: result });
    }
  }

  onChange = (e) => {
    const { id, value } = e.target;
    const { formValue } = this.state;

    const formData = { ...formValue, ...{ [id]: value } };
    this.setState({ formValue: formData });
  };

  profileUpdate = async (e) => {
    e.preventDefault();
    const { name, email, phoneNumber } = this.state.formValue;

    let reqData = {
      name,
      email,
      phoneNumber,
    };
    const { status, message, errors } = await profileUpdatae(reqData);
    this.setState({ loader: false });
    if (status == "success") {
      this.setState({ errors: {}, loader: false });
      toastAlert("success", message, "profileUpdatae");
      this.getData();
    } else {
      this.setState({ errors: errors });
    }
  };

  render() {
    const { errors } = this.state;
    const { name, email, phoneNumber } = this.state.formValue;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary">Edit Profile</h3>
              {this.state.loader === true ? (
                <p>Loading ...</p>
              ) : (
                <>
                  <form
                    noValidate
                    onSubmit={this.profileUpdate}
                    id="update-profile"
                  >
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="name">Name</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onChange={this.onChange}
                          value={name}
                          id="name"
                          type="text"
                          error={errors.name}
                          className={classnames("form-control", {
                            invalid: errors.name,
                          })}
                        />
                        <span className="text-danger">{errors.name}</span>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="email">Email</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onChange={this.onChange}
                          value={email}
                          id="email"
                          type="text"
                          error={errors.email}
                          className={classnames("form-control", {
                            invalid: errors.name,
                          })}
                        />
                        <span className="text-danger">{errors.email}</span>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="phonenumber">Phone Number</label>
                      </div>
                      <div className="col-md-6">
                        <input
                          onChange={this.onChange}
                          value={phoneNumber}
                          id="phoneNumber"
                          type="text"
                          error={errors.phonenumber}
                          className={classnames("form-control", {
                            invalid: errors.phoneNumber,
                          })}
                        />
                        <span className="text-danger">
                          {errors.phoneNumber}
                        </span>
                      </div>
                    </div>

                    {/*}<div className="row mt-2">
                                        <div className="col-md-3">
                                        <br/>
                                        <label htmlFor="profile">Profile Image</label>
                                        </div>
                                        <div className="col-md-6">
                                        <input type="file" onChange={this.handleChange}
                                        />
                                        <img width="100px" src={this.state.profileurl} />

                                        </div>
                                    </div>
                                    */}
                  </form>
                  <br />
                  <button
                    form="update-profile"
                    type="submit"
                    className="btn btn-primary"
                  >
                    Update Profile
                  </button>
                </>
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default Profile;

import React, { Component } from "react";
import { Card, Button, Form } from "react-bootstrap";
import Select from "react-select";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import SiteDetails from "../partials/siteDetals";
import UserDashboard from "../partials/UserDashboard";
import SocialMedia from "../partials/SocialMedia";
import MailUpdate from "../partials/EmailIntegration";
import FaqTrend from "../partials/FaqTrend";
import Sms from "../partials/Sms";

// import action
import {
  getSiteSetting,
  updateSiteSetting,
  getMailIntegrate,
  updateMailIntegrate,
} from "../../actions/siteSettingActions";
import { getPairDropdown } from "../../actions/commonActions";
import { getCurrency } from "../../actions/currency";
import { getFaqDropdown } from "../../actions/faqActions";

//lib
import { toastAlert } from "../../lib/toastAlert";

const initialFormValue = {
  fromemail: "",
  host: "",
  port: "",
  secure: "",
  user: "",
  pass: "",
};

class SiteSettingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: initialFormValue,
      pairListOption: [],
      currencyOption: [],
      faqOption: [],
      marketTrend: [],
      records: {},
      loader: false,
      errors: {},
    };

    this.fetchSiteSetting = this.fetchSiteSetting.bind(this);
    this.handlePairChange = this.handlePairChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchCurrency = this.fetchCurrency.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.fetchSiteSetting();
    this.fetchPairDropdown();
    this.fetchCurrency();
    // this.fetchFaqDropdown();
  }

  async fetchSiteSetting() {
    try {
      this.setState({ loader: true });
      const { status, loading, result } = await getSiteSetting();
      if (status == "success") {
        this.setState({ loader: false });
        this.setState({ marketTrend: result.marketTrend, records: result });
      }
    } catch (err) {}
  }

  async fetchPairDropdown() {
    try {
      const { status, loading, result } = await getPairDropdown();
      if (status == "success") {
        let option = [];
        result &&
          result.length > 0 &&
          result.map((item, key) => {
            option.push({
              label: item.firstCurrencySymbol + item.secondCurrencySymbol,
              value: item._id,
            });
          });
        this.setState({ pairListOption: option });
      }
    } catch (err) {}
  }

  //   async fetchFaqDropdown() {
  //     try {
  //       const { status, loading, result } = await getFaqDropdown();
  //       if (status == "success") {
  //         let option = [];
  //         result &&
  //           result.length > 0 &&
  //           result.map((item, key) => {
  //             option.push({
  //               label: item.question,
  //               value: item._id,
  //             });
  //           });
  //         this.setState({ faqOption: option });
  //       }
  //     } catch (err) {}
  //   }

  async fetchCurrency() {
    try {
      this.setState({ loader: true });
      const { status, result } = await getCurrency();
      if (status == "success") {
        this.setState({ loader: false });
        this.setState({ currencyOption: result });
      }
    } catch (err) {}
  }

  async fetchSiteSetting() {
    try {
      this.setState({ loader: true });
      const { status, loading, result } = await getSiteSetting();
      if (status == "success") {
        this.setState({ loader: false });
        this.setState({ marketTrend: result.marketTrend, records: result });
      }
    } catch (err) {}
  }

  handlePairChange(selectedOption) {
    if (selectedOption && selectedOption.length > 0) {
      this.setState({
        marketTrend: selectedOption.map((el) => {
          return el.value;
        }),
      });
    } else {
      this.setState({ marketTrend: [] });
    }
  }

  handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { marketTrend } = this.state;

    let reqData = {
      marketTrend,
    };

    try {
      this.setState({ loader: true });
      const { status, loading, result } = await updateSiteSetting(reqData);
      if (status == "success") {
        this.setState({ loader: false });
        toastAlert("success", "MarketTrend updated successfully", "faqTrend");
        this.setState({ marketTrend: result.marketTrend });
      }
    } catch (err) {}
  }

  //   componentDidMount() {
  //     this.fetchSiteSetting();
  //     this.fetchPairDropdown();
  //     this.fetchCurrency();
  //     this.fetchFaqDropdown();
  //   }

  render() {
    const {
      pairListOption,
      marketTrend,
      currencyOption,
      records,
      errors,
      faqOption,
      loader,
    } = this.state;
    const { fromemail, host, port, secure, user, pass } = this.state.formValue;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h3 className="mt-2 text-secondary"> Site Setting</h3>
              <Card>
                <Card.Header>Market Trend</Card.Header>
                <Card.Body>
                  {loader == true ? (
                    <p>Loading...</p>
                  ) : (
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label htmlFor="currencyName">Pair Name</label>
                      </div>
                      <div className="col-md-9">
                        <Select
                          value={
                            pairListOption && pairListOption.length > 0
                              ? pairListOption.filter((el) => {
                                  if (marketTrend.includes(el.value)) {
                                    return el;
                                  }
                                })
                              : []
                          }
                          isMulti
                          name="colors"
                          options={pairListOption}
                          onChange={this.handlePairChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
                <Card.Footer>
                  {loader === false ? (
                    <Button onClick={this.handleSubmit}>Submit</Button>
                  ) : null}
                </Card.Footer>
              </Card>
              {/* <FaqTrend records={records} faqOption={faqOption} /> */}

              <SiteDetails
                records={records}
                fetchSiteSetting={this.fetchSiteSetting}
                loader={loader}
              />
              <UserDashboard
                records={records}
                currencyOption={currencyOption}
                loader={loader}
              />
              <SocialMedia
                records={records}
                currencyOption={currencyOption}
                loader={loader}
              />
              <SocialMedia records={records} currencyOption={currencyOption} />
              <MailUpdate loader={loader} />
              <Sms records={records} fetchSiteSetting={this.fetchSiteSetting} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SiteSettingPage;

// import package
import React from 'react'
import classnames from "classnames";
import moment from 'moment'
// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { Accordion, Card, Button, Modal } from 'react-bootstrap';

// import action
import { getMessage, replyMsg } from "../../actions/supportAction";

// import lib
import { toastAlert } from '../../lib/toastAlert'
import isEmpty from '../../lib/isEmpty';
import config from '../../config';
import { getImg } from '../../actions/admin';
const initialFormValue = {
    "message": "",
}

class SupportReply extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            records: {},
            msgConversation: [],
            formValue: initialFormValue,
            errors: {},
            loader: false,
            isShow: false,
            Img: ''
        };
    }

    componentDidMount() {
        this.fetchTicktMessage()
    }

    async fetchTicktMessage() {
        try {
            let reqData = {
                ticketId: this.props.match.params.id
            }
            this.setState({ 'loader': true })
            const { status, loading, result, errors } = await getMessage(reqData);
            this.setState({ 'loader': loading })
            if (status == 'success') {
                this.setState({ 'records': result, "msgConversation": result.reply })
            }

        } catch (err) { }

    }

    handleChange = e => {
        e.preventDefault();
        let { name, value } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        this.setState({ formValue: formData });
        this.setState({ errors: '' })
    };

    handleSubmit = async e => {
        e.preventDefault();
        const { formValue, records } = this.state
        this.setState({ 'loader': true })

        let reqData = {
            'ticketId': records._id,
            'receiverId': records.userId,
            'message': formValue.message
        }
        try {
            const { status, loading, message, error, result } = await replyMsg(reqData);
            this.setState({ 'loader': loading })
            if (status == 'success') {
                toastAlert('success', message, 'replyMsg')
                this.setState({ "formValue": initialFormValue, 'msgConversation': result.reply })
            } else {
                toastAlert('error', message, 'replyMsg')
            }
            if (error) {
                this.setState({ errors: error })
            }
        } catch (err) {
            this.setState({ 'loader': false })
        }
    };


    handleImg = async (e) => {
        let { result } = await getImg(e)
        this.setState({ Img: result })
        this.setState({ isShow: true })
    }
    handleClose = () => this.setState({ isShow: false })

    render() {
        const { errors, records, msgConversation, loader } = this.state;
        const { message } = this.state.formValue;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <Modal
                                show={this.state.isShow}
                                onHide={this.handleClose}
                                // backdrop="static"
                                keyboard={false}
                                size="md"
                                centered
                            >
                                <Modal.Body>
                                    <embed src={this.state.Img} width="480px" height="500px" />
                                </Modal.Body>
                            </Modal>

                            <h3 className="mt-2 text-secondary">Support Ticket </h3>

                            {/* <div className="row rounded-lg overflow-hidden shadow"> */}
                            {/* Chat Box*/}
                            <div className="px-4 py-5 chat-box bg-white">
                                {
                                    !isEmpty(records) && msgConversation && msgConversation.length > 0 && msgConversation.map((item, key) => {
                                        let attachment = item.attachment;
                                        if (item.senderId == records.adminId) {
                                            return (
                                                <div className="media w-50 ml-auto mb-3">
                                                    <div className="media-body">

                                                        <div className="bg-primary rounded py-2 px-3 mb-2">
                                                            <p className="text-small mb-0 text-white">
                                                                {item.message}
                                                            </p>


                                                        </div>
                                                        <p className="small text-muted">{moment(item.createdAt).format("'DD-MM-YYYY HH:mm'")}</p>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="media w-50 mb-3">
                                                    <img src="https://bootstrapious.com/i/snippets/sn-chat/avatar.svg" alt="user" width={50} className="rounded-circle" />
                                                    <div className="media-body ml-3">
                                                        <div className="bg-light rounded py-2 px-3 mb-2">
                                                            <p className="text-small mb-0 text-muted">
                                                                {item.message}
                                                            </p>
                                                        </div>
                                                        <p className="small text-muted">{moment(item.createdAt).format("'DD-MM-YYYY HH:mm'")}</p>
                                                    </div>
                                                    <div className="ticketDetails">
                                                        {/* {
                                                            records && records.length > 0 && records.map(item => {
                                                                return console.log(item, '-------------item')
                                                            })
                                                        } */}
                                                        {!isEmpty(item.attachment) &&
                                                            <a target="_blank" onClick={() => this.handleImg(attachment)}><i class="fa fa-paperclip fa-2x" aria-hidden="true"></i></a>
                                                            // <a href={`${config.API_URL}/support/${item.attachment}`} target="_blank" ><i class="fa fa-paperclip fa-2x" aria-hidden="true"></i></a>

                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>

                            {/* Typing area */}
                            <form className="bg-light">
                                {
                                    records && records.status == "open" &&
                                    <div className="input-group">
                                        <textarea
                                            type="text"
                                            placeholder="Type a message"
                                            aria-describedby="button-addon2"
                                            className="form-control rounded-0 border-0 py-4 bg-light"
                                            name="message"
                                            value={message}
                                            onChange={this.handleChange}
                                        />
                                        <span style={{ color: 'red' }}>{errors.message}</span>
                                        <div className="input-group-append">
                                            <button
                                                onClick={this.handleSubmit}
                                                disabled={loader}
                                                className="btn btn-link"> <i className="fa fa-paper-plane" />
                                            </button>
                                        </div>
                                    </div>
                                }

                            </form>
                            <br />
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SupportReply;
import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";
import Select from 'react-select';
// import lib
import { toastAlert } from '../../lib/toastAlert';
import fileObjectUrl from '../../lib/fileObjectUrl'
import isEmpty from '../../lib/isEmpty'
// import action
import { EditAdmin } from '../../actions/admin';
// import options from '../../lib/selectOption'
// import Routers from '../../lib/routes'
import nav from './nav';

// const Options = nav;

let initialValue = {
    adminId: '',
    email: '',
    name: '',
}

// const Options = [

//     { value: "/dashboard", label: 'Dashboard' },
//     { value: "/sub-admin", label: 'SubAdmin' },
//     { value: "/users", label: 'User' },
//     { value: "/site-setting", label: 'SiteSettingPage' },
//     { value: "/cms", label: 'Cms' },
//     { value: "/contactus", label: 'Contactus' },

//     { value: "/faq-category", label: 'FaqCategory' },
//     { value: "/faq", label: 'FaqPage' },
//     { value: "/emailtemplates", label: 'Emailtemplates' },
//     { value: "/perpetual", label: 'Perpetual' },
//     { value: "/priceCNVlist", label: 'priceConversion' },
//     { value: "/anouncement", label: 'Anouncement' },
//     { value: "/support-category", label: 'SupportCategory' },
//     { value: "/support", label: 'Support' },
//     { value: "/support_reply/:id", label: 'SupportReply' },
//     { value: "/security", label: 'GoogleAuth' },


//     { value: "/kyc", label: 'Kyc' },
//     { value: "/currency", label: 'Currency' },

//     { value: "/profile", label: 'Profile', },
//     { value: "/settings", label: 'Settings' },
//     { value: "/changepassword", label: 'Changepassword' },


//     { value: "/orderhistory", label: 'OrderHistory' },
//     { value: "/tradehistory", label: 'TradeHistory' },
//     { value: "/perpetual-Trade-History", label: 'perpetualTradeHistory' },
//     { value: "/perpetual-Order-History", label: 'PerpetualOrderHistory' },
//     { value: "/withdraw", label: 'Withdraw' },
//     { value: "/deposit", label: 'Deposit' },
//     { value: "/Pairmanagement", label: 'Pairmanagement' },
//     { value: "/Tradingbot", label: 'Tradingbot' },
//     { value: "/Staking", label: 'Staking' },
//     { value: "/Staking-History", label: 'StakingHistory' },
//     { value: "/launchpad", label: 'Launchpad' },
//     { value: "/token-purchase", label: 'TokenPurchase' },
//     { value: "/kyc", label: 'Kyc' },

//     { value: "/Bonus", label: 'Bonus' },
//     { value: "/contactus", label: 'Contactus' },
//     // { value: "/chat", name: 'Chat', exact: true, component: Chat },
//     { value: "/newsletter", label: 'Newsletter' },
//     // { value: "/assets", name: 'Assets', exact: true, component: Assets },
//     { value: "/closedpositions", label: 'Closedpositions' },
//     { value: "/feesettings", label: 'Closedpositions' },
//     { value: "/liquidated", label: 'Liquidated' },
//     // { value: "/category", name: 'Category', exact: true, component: Category },
//     // { value: "/subcategory", name: 'Subcategory', exact: true, component: Subcategory },
//     // { value: "/article", name: 'Article', exact: true, component: Article },

//     // { value: "/HelpCentreCategory", name: 'HelpCentreCategory', exact: true, component: HelpCentreCategory },
//     // { value: "/HelpCentreArticle", name: 'HelpCentreArticle', exact: true, component: HelpCentreArticle },


//     { value: "/p2p-pair", label: 'P2pPair' },
//     { value: "/p2p-order", label: 'P2pOrder' },
//     { value: "/p2p-ordrView/:orderId", label: 'P2pOrderView' },
//     { value: "/p2p-dispute", label: 'P2pDispute' },
//     { value: "/kycupdate/:id", label: 'kycupdate' },

// ]



class EditAdminModal extends React.Component {
    constructor() {
        super()
        this.state = {
            formValue: initialValue,
            errors: {},
            selectedOption: null,
            restriction: [],
            Options: []

        }

    }



    componentWillReceiveProps(nextprops) {
        const { record } = nextprops

        if (!isEmpty(record)) {
            this.setState({
                formValue: {
                    email: record.email,
                    name: record.name,
                    adminId: record._id,
                },
                restriction: record.restriction
            })
        }

    }

    componentDidMount() {
        let data = nav && nav.length > 0 && nav.map((item, i) => {
            return {
                label: item.name,
                value: item.path
            }
        })

        this.setState({ Options: data })
    }


    copyText = (e) => {
        e.preventDefault()
    }


    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        this.setState({ 'formValue': initialValue, errors: {} });
    }


    handleSubmit = async (e) => {
        e.preventDefault()
        let { name, email, adminId, path } = this.state.formValue
        let { restriction } = this.state
        let respData = {
            name: name,
            email: email,
            adminId: adminId,
            restriction: restriction
            // role: role,
            // validErr: {}
        }

        let { status, error, message } = await EditAdmin(respData)
        if (status) {
            toastAlert('success', message, 'add admin')
            this.setState({ formValue: { ...this.state.formValue, ...initialValue } })
        } else {
            toastAlert('error', message, 'add admin')
        }

        if (error) {
            this.setState({ errors: error })
        }


    }

    handleChange = (e) => {
        e.preventDefault()
        let { name, value } = e.target
        this.setState({ formValue: { ...this.state.formValue, ...{ [name]: value } } })
        if (!isEmpty(value)) {
            this.setState({ errors: {} })
        }
    }


    selectorChange = (selectedOption) => {

        if (selectedOption && selectedOption.length > 0) {
            console.log("---- selectedOption.map((el) => { return el.value; })", selectedOption.map((el) => { return el.value; }))
            this.setState({
                "restriction": selectedOption.map((el) => { return el.value; })
            })
        } else {
            this.setState({ "restriction": [] })
        }

    };


    render() {
        const { name, email, adminId, path } = this.state.formValue;
        const { errors, loader, restriction, Options } = this.state;
        console.log(restriction, 'restrictionrestrictionrestriction')
        const { isShow } = this.props;
        return (
            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">Edit Admin</h4>
                    </Modal.Header>
                    <Modal.Body>

                        <form noValidate            >
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label>Name:</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        name="name"
                                        type="text"
                                        value={name}
                                        onChange={this.handleChange}
                                        // error={errors.name}
                                        className={classnames("form-control", {
                                            // invalid: errors.name,
                                        })}
                                    />
                                    <span className="text-danger">{errors.name}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label>Email</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        name="email"
                                        type="text"
                                        value={email}
                                        onChange={this.handleChange}
                                        // error={errors.email}
                                        className={classnames("form-control", {
                                            // invalid: errors.email,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.email}
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label>Restriction</label>
                                </div>
                                <div className="col-md-9">
                                    <Select
                                        value={restriction && restriction.length > 0 ? Options.filter((el) => {

                                            if (restriction.includes(el.value)) {
                                                return el;

                                            }
                                        }) : []}
                                        isMulti
                                        onChange={this.selectorChange}
                                        options={Options}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                            </div>
                        </form>

                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={this.handleClose}
                        >
                            Close
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            className="btn btn-primary"
                        >
                            {loader && <i class="fas fa-spinner fa-spin"></i>}submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </div >
        );
    }
}

export default EditAdminModal

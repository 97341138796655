import React, { Component, useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Link } from "react-router-dom";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Scrollbars } from 'react-custom-scrollbars';
import store from '../../store'
//lib
import nav from './nav'
import isEmpty from '../../lib/isEmpty'

//action
import { SingleAdmin } from '../../actions/admin'


const initialFormValue = {
    'show': (window.innerWidth > 768) ? true : false
}
const Sidebar = () => {

    // state 
    const [formValue, setFormValue] = useState(initialFormValue);
    const { show } = formValue;

    // redux-state
    let { restriction, role } = useSelector((state) => state.isRun);

    const onLogoutClick = (e) => {
        e.preventDefault();
    };

    const changestate = (e) => {
        setFormValue({ show: show ? false : true });
    }


    return (
        <div>
            <button className="btn" onClick={changestate} id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
            {show && <div className="border-right" >
                <div className="list-group list-group-flush">
                    <Scrollbars style={{ width: 250, height: "88.5vh" }} >
                        <div>
                            {
                                nav && nav.length > 0 && nav.map((item, i) => {
                                    if (item.sidemenu) {


                                        let filterData = restriction && restriction.length > 0 && restriction.find(item => item == '/dashboard');

                                        if (!filterData) {
                                            restriction && restriction.length > 0 && restriction.push('/dashboard')
                                        }
                                        if (
                                            (role && role == 'superadmin') ||
                                            (role && role == 'admin' && restriction && restriction.length > 0 && restriction.includes(item.path))
                                        ) {
                                            return (
                                                <Link to={item.path} className="list-group-item list-group-item-action">{item.name}</Link>
                                            )
                                        }
                                    }
                                })
                            }

                        </div>
                    </Scrollbars>

                </div>

            </div>}
        </div>
    );
}

export default Sidebar;